import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import AppConfig from "../../config/app.config";

const StreamIframe = ({
  hide,
  link,
  orderId,
  handleClick,
  employeeData,
  isReactPlayer = false,
}) => {

  return (
    <section
      className="main-carousel"
      id="home-streamiframe"
      style={{ position: "relative" }}
    >
      {/* {orderId != null && orderId !== -1 && orderId !== "" ? ( */}

        <>
          {AppConfig.API.EVENT_ID !== 3002276 ? (
            <ReactPlayer
              url={link}
              playing
              controls
              width="100%"
              height="100%"
              className="video-player"
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
            />
          ) : (
            <iframe
              src={link}
              allowFullScreen
              frameBorder="0"
              className="video-player"
              style={{
                height: "100%",
                width: "100%",
              }}
              title="live-stream"
            ></iframe>
          )}
        </>

        <>
          {/* {hide && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "100",
              }}
            >
              <h3 style={{ textAlign: "center", fontSize: "32px" }}>
                {AppConfig.API.EVENT_ID != 2980039
                  ? "Preview complete! Ready to experience more? Sign Up now to start streaming."
                  : " Preview complete! Ready to experience more? Purchase now to start streaming."}
              </h3>
              <Link to="/authentication/my-account">
                <button
                  className="ordernow-btn"
                  style={{ marginInline: "auto", display: "block" }}
                >
                  Register Now
                </button>
              </Link>
              <button
                className="ordernow-btn"
                style={{ marginInline: "auto", display: "block" }}
                onClick={handleClick}
              >
                Order Now
              </button>
            </div>
          )} */}
          {/* {!isReactPlayer ? (
            <iframe
              src={hide ? "" : link}
              allowFullScreen
              frameBorder="0"
              className="video-player"
              style={{
                height: "100%",
              }}
              title="live-stream"
            ></iframe>
          ) : (
            <ReactPlayer
              url={hide ? "" : link}
              playing
              controls
              width="100%"
              height="100%"
              className="video-player"
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
            />
          )} */}
        </>

    </section>
  );
};

export default StreamIframe;
