import { Component } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Closeicon } from "../../assets/images/close_icon_black.svg";
import Loader from "../../components/loader/loader";
import { NotificationManager } from "react-notifications";
import validator from "validator";

const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");

class WaitList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      email: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleEmail = (event) => {
    this.setState({ email: removeExtraSpace(event.target.value) });
  };

  addToWaitList = () => {
    if (this.state.email.trim() === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else {
      this.props.saveWiatList(this.state.email);
    }
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // id="wait-list-modal"
      >
        {this.state.isLoading === 1 ? <Loader /> : ""}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "16px",
            paddingTop: "14px",
          }}
        >
          <Closeicon
            className="close-icon"
            onClick={() => this.props.onHide()}
          />
        </div>

        <div className="order-confiramtion-modal">
          <h5
            style={{
              fontFamily: "Avenir",
              fontWeight: "300",
              fontSize: "24px",
              letterSpacing: "0.43px",
              color: "#000",
              transform: "uppercase",
              marginLeft: "20px",
            }}
          >
            JOIN THE WAIT LIST
          </h5>
          <div style={{ padding: "24px" }}>
            <input
              type="email"
              placeholder="Email"
              className="contact-field"
              value={this.state.email}
              onChange={this.handleEmail}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
            />

            <div
              className="contact-send-btn"
              style={{ justifyContent: "center" }}
            >
              <button
                type="submit"
                className="send-btn1"
                onClick={this.addToWaitList}
              >
                Wait List
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default WaitList;
