import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AppConfig from "../../config/app.config";
import { Form } from "react-bootstrap";
import { ReactComponent as Returncart } from "../../assets/images/returncart.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import validator from "validator";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import {
  userSignup,
  userSignupFailure,
} from "../../store/signUp/signUp.actions";
import { selectSignUpStatus } from "../../store/signUp/signUp.selectors";
import { selectSigninRedirectPath } from "../../store/home/home.selectors";
import SignupSuccessModal from "../../components/model/signup-success-modal";
import Loader from "../loader/loader";
import { selectLoginStatus } from "../../store/signIn/signIn.selectors";
import { userLogin, userLoginFailure } from "../../store/signIn/signIn.actions";
import UserService from "../../services/user/user.service";
import axios from "axios"
import { Visibility, VisibilityOff } from "@mui/icons-material";

const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");
var isNotifiaction = 0;

const mapStateToProps = (state) => {
  return {
    selectSignUpStatus: selectSignUpStatus(state),
    selectSigninRedirectPath: selectSigninRedirectPath(state),
    selectLoginStatus: selectLoginStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
      userSignup,
      userSignupFailure,
      userLogin,
      userLoginFailure,
    },
    dispatch
  ),
});

export class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,

      email: "",
      phone: "",
      userName: "",
      // lastName: "",
      password: "",
      passwordProgress: 0,
      repeatedPassword: "",
      isDisabled: "",
      passwordType: "password",
      repeatedPasswordType: "password",
      termsAndCondition: false,
      isSuccessModalVisible: false,
      termsData: [],
      ipaddress: "",
      showPassword: false,
      showRePassword: false,
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleuserName = this.handleuserName.bind(this);
    // this.handleLastName = this.handleLastName.bind(this);
    this.handleTermsAndCondition = this.handleTermsAndCondition.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleRepeatedPassword = this.handleRepeatedPassword.bind(this);
  }

  componentDidMount() {
    const getData = async () => {
      try {
        const res = await axios.get("https://api.ipify.org/?format=json");
        this.setState({ ipaddress: res.data.ip });
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    getData();

    UserService.getTerms(AppConfig.API.APP_ID).then((res) => {
      if (res.error != null) {
        NotificationManager.error(res.error.response.data.message, "", 2000);
      } else if (res.status) {
        let x = [];
        if (res.data != null && res.data.length > 0) {
          res.data.map((i) => {
            x.push(i.termId);
          });
        }
        this.setState({ termsData: x });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectSignUpStatus,
      userSignupFailure,
      selectLoginStatus,
      userLoginFailure,
    } = this.props;
    if (isNotifiaction === 1) {
      if (selectLoginStatus.success != null) {
        if (selectLoginStatus.success) {
          isNotifiaction = 0;
          NotificationManager.success("Login Successfully.", "", 2000);
          this.timeoutHandle = setTimeout(() => {
            this.setState({ isLoading: 0, isDisabled: "" });
            this.setState({
              isSuccessModalVisible: true,
            });
          }, 3000);

          userLoginFailure({ loginSuccess: false, loading: false, err: null });
        } else {
          if (selectLoginStatus.error != null) {
            NotificationManager.error(selectLoginStatus.error, "", 2000);
            isNotifiaction = 0;
            this.timeoutHandle = setTimeout(() => {
              this.setState({ isLoading: 0 });
            }, 3000);
            userLoginFailure({
              loginSuccess: false,
              loading: false,
              err: null,
            });
          }
        }
      }

      if (selectSignUpStatus.success !== null) {
        if (selectSignUpStatus.success) {
          NotificationManager.success(
            "User Registration Successful.",
            "",
            2000
          );
          isNotifiaction = 0;

          this.loginUser(this.state.email, this.state.password);

          userSignupFailure({
            signUpSuccess: false,
            loading: false,
            err: null,
          });
        } else {
          if (selectSignUpStatus.error !== null) {
            if (selectSignUpStatus.error !== "") {
              userSignupFailure({
                signUpSuccess: false,
                loading: false,
                err: null,
              });
              NotificationManager.error(selectSignUpStatus.error, "", 2000);
              isNotifiaction = 0;
              this.timeoutHandle = setTimeout(() => {
                this.setState({
                  isLoading: 0,
                  email: "",
                  phone: "",
                  userName: "",
                  lastName: "",
                  password: "",
                  passwordProgress: 0,
                  repeatedPassword: "",
                  isDisabled: "",
                  termsAndCondition: false,
                });
              }, 3000);
            }
            isNotifiaction = 0;
          }
        }
      }
    }
  }

  loginUser(email, password) {
    const { userLogin } = this.props;
    const payload = {
      username: email,
      password: password,
    };
    isNotifiaction = 1;
    this.setState({ isLoading: 1 });
    userLogin(payload);
  }

  handleEmail(event) {
    this.setState({ email: removeExtraSpace(event.target.value) });
  }
  handlePhone(event) {
    const phoneNumber = event.target.value.replace(/\D/g, "");
    let formatted = "";
    if (phoneNumber.length > 3) {
      formatted += phoneNumber.substr(0, 3) + "-";
      if (phoneNumber.length > 6) {
        formatted += phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
      } else {
        formatted += phoneNumber.substr(3);
      }
    } else {
      formatted = phoneNumber;
    }
    this.setState({ phone: formatted });
  }
  handleuserName(event) {
    this.setState({ userName: event.target.value });
  }
  // handleLastName(event) {
  //   this.setState({ lastName: removeExtraSpace(event.target.value) });
  // }
  handleTermsAndCondition(event) {
    this.setState({ termsAndCondition: event.target.checked });
  }
  handlePassword(event) {
    const pass = event.target.value;
    const hasLength = pass.length >= 8 ? 1 : 0;
    const hasUppercase = /[A-Z]/.test(pass) ? 1 : 0;
    const hasLowercase = /[a-z]/.test(pass) ? 1 : 0;
    const hasNumber = /[0-9]/.test(pass) ? 1 : 0;
    const progress =
      ((hasLength + hasUppercase + hasLowercase + hasNumber) / 4) * 100;
    this.setState({
      password: event.target.value,
      passwordProgress: progress,
    });
  }
  handleRepeatedPassword(event) {
    this.setState({ repeatedPassword: event.target.value });
  }

  signUp = () => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const rule = new RegExp(/^[a-zA-Z ]{2,20}$/);
    const fNumber = this.state.phone.split("-").join("");
    const { userSignup } = this.props;
    if (this.state.userName.trim() === "") {
      NotificationManager.warning("Please Enter User Name.", "", 2000);
    }
    //  else if (this.state.lastName.trim() === "") {
    //   NotificationManager.warning("Please Enter Last Name.", "", 2000);
    // } else if (!rule.test(this.state.lastName)) {
    //   NotificationManager.warning(
    //     "Last Name must contains at least two letter with only characters, not allow numaric value.",
    //     "",
    //     2000
    //   );
    // }
    else if (this.state.email.trim() === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else if (fNumber.trim() === "") {
      NotificationManager.warning("Please Enter Phone.");
    } else if (!pattern.test(fNumber)) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
    } else if (fNumber.length !== 10) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
    } else if (this.state.password === "") {
      NotificationManager.warning("Please Enter Password.", "", 2000);
    } else if (!validator.isStrongPassword(this.state.password)) {
      NotificationManager.warning(
        "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character and length between 8 to 20 characters.",
        "",
        2000
      );
    } else if (this.state.repeatedPassword === "") {
      NotificationManager.warning("Please Enter Repeated Password.", "", 2000);
    } else if (this.state.repeatedPassword !== this.state.password) {
      NotificationManager.warning("Password's do not match.", "", 2000);
    } else if (!this.state.termsAndCondition) {
      NotificationManager.warning(
        "Please accept terms and conditions.",
        "",
        2000
      );
    } else {
      const payload = {
        email: this.state.email,
        phone: fNumber,
        name: this.state.userName,
        password: this.state.password,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID,
        confirmPassword: this.state.repeatedPassword,
        termAgreementIds: this.state.termsData,
        ipaddress: this.state.ipaddress,
      };
      isNotifiaction = 1;
      this.setState({ isLoading: 1, isDisabled: "disabled" });
      userSignup(payload);
    }
  };

  redirectPage = () => {
    this.setState({ isSuccessModalVisible: false });
    this.props.redirectPage();
  };

  onHide = () => {
    if (this.props.selectSigninRedirectPath != null) {
      this.props.setSigninRedirectPath({
        signinRedirectPath: null,
      });
    }
    this.redirectPage();
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 && <Loader />}
        <div className="create-acoount-content">
          <p>Create account</p>
          <div className="createacoount-form">
            <Form.Group>
              <label className="label">User name</label>
              <Form.Control
                type="text"
                className="mb-0 create-signin"
                maxLength="20"
                id="txtFirstname"
                // placeholder="User Name"
                value={this.state.userName}
                onChange={this.handleuserName}
                // onKeyDown={(e) => {
                //   if (e.key === " ") {
                //     e.preventDefault();
                //   }
                // }}
                autoComplete="off"
                required
              />
            </Form.Group>
            {/* <Form.Group>
              <label className="label">LAST NAME</label>
              <Form.Control
                type="text"
                className="mb-0 create-input"
                maxLength="20"
                id="txtLastname"
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this.handleLastName}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                required
              />
            </Form.Group> */}
          </div>
          <div className="createacoount-form">
            <Form.Group>
              <label className="label">email address</label>
              <Form.Control
                type="email"
                className="mb-0 create-input"
                id="txtEmail"
                // placeholder="Email"
                value={this.state.email}
                onChange={this.handleEmail}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                required
              />
            </Form.Group>
          </div>
          <div className="createacoount-form">
            <Form.Group>
              <label className="label">Mobile #</label>
              <Form.Control
                type="text"
                className="mb-0 create-input"
                maxLength="12"
                id="txtPhone"
                // placeholder="Phone Number"
                value={this.state.phone}
                onChange={this.handlePhone}
                autoComplete="off"
                required
              />
            </Form.Group>
          </div>
          <div className="createacoount-form" id="accept-pwd">
            <Form.Group>
              <label className="label">Password</label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type={this.state.showPassword ? "text" : "password"}
                  className="mb-0 create-input"
                  id="txtPassword"
                  value={this.state.password}
                  onChange={this.handlePassword}
                  required
                />
                <button
                  type="button"
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer"
                  }}
                >
                  {this.state.showPassword ? <Visibility color="black" /> : <VisibilityOff color="black" />}
                </button>
              </div>
            </Form.Group>
          </div>
          <div className="createacoount-form">
            <Form.Group>
              <label className="label">Password Strength</label>
              <ProgressBar
                now={this.state.passwordProgress}
                className={`progress-accountcretaion ${this.state.passwordProgress > 75
                    ? "strong"
                    : this.state.passwordProgress > 50
                      ? "medium"
                      : "weak"
                  }`}
              />
            </Form.Group>
          </div>
          <div className="createacoount-form" id="portal-acceptterms">
            <p id="accept-terms1">
              Password must be green-striped (strong) to proceed. Requires at
              least 8 characters, including 1 uppercase letter, 1 lowercase
              letter, and 1 number.
            </p>
          </div>
          <div className="createacoount-form" id="portal-acceptterms">
            <Form.Group>
              <label className="label">Re-enter Password</label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type={this.state.showRePassword ? "text" : "password"}
                  className="mb-0 create-input"
                  id="txtRepeatedPassword"
                  // placeholder="Repeat Password"
                  value={this.state.repeatedPassword}
                  onChange={this.handleRepeatedPassword}
                  required
                />
                <button
                  type="button"
                  onClick={() => this.setState({ showRePassword: !this.state.showRePassword })}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer"
                  }}
                >
                  {this.state.showRePassword ? <Visibility color="black" /> : <VisibilityOff color="black" />}
                </button>
              </div>
            </Form.Group>
            {/* <div className="createacoount-form" id="terms-account">
              <p id="accept-terms">
                By continuing past this page, you agree to the
                <span>
                  <Link to="/terms-of-use"> Terms of Use </Link>
                </span>
                and understand that your information will be used as described
                in our
                <span>
                  <Link to="/privacy-policy"> Privacy Policy.</Link>
                </span>
              </p>
            </div> */}
          </div>
          <div className="createacoount-form">
            <Form.Group>
              <Form.Check
                type="checkbox"
                label={
                  <span style={{ color: "white" }} className="label">
                    I agree to the terms and conditions
                  </span>
                }
                className="mb-0 d-flex align-items-center"
                id="termsAndConditions"
                checked={this.state.termsAndCondition}
                onChange={this.handleTermsAndCondition}
                required
                style={{ color: "white" }}
              />
            </Form.Group>
          </div>
          <div className="checkout-btn">
            <div className="checkout-returncart">
              <Returncart className="arrow-img" />
              <Link to="/buy-ticket">
                <p id="back-cart">Return</p>
              </Link>
            </div>
            <button
              className="Create-Account"
              type="submit"
              onClick={this.signUp}
            >
              Create Account
            </button>
          </div>
        </div>
        <SignupSuccessModal
          show={this.state.isSuccessModalVisible}
          onHide={this.onHide}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);