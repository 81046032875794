import React from "react"
import { Link } from "react-router-dom"
import AppConfig from "../../config/app.config"
import {
  selectemployeeData,
  selecteventData,
} from "../../store/home/home.selectors"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Affiliates from "../../views/Affiliates/Affiliates"
import ContactUsSuccessMoal from "../../components/model/contact-us-success-modal"
import ContactUsModal from "../../components/model/contact-us-modal"

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
    eventData: selecteventData(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
})

export class FooterManu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pathName: "",
      employeeData: props.employeeData,
      isAffiliateModalOpen: false,
      eventData: null,
      displayText: "",
      isContactUsSuccessModal: false,
      isContactUsModal: false,
    }
  }

  async componentDidMount() {
    const pathArray = window.location.pathname.split("/")
    if (pathArray.length >= 2) {
      this.setState({ pathName: pathArray[1] })
    }

    // if (localStorage.getItem("eventData") != null) {
    //   const eventData = await JSON.parse(localStorage.getItem("eventData"));
    //   this.setState({ eventData: eventData });

    // }
  }

  async componentDidUpdate(prevProps, prevState) {
    const pathArray = window.location.pathname.split("/")
    if (pathArray.length >= 2 && this.state.pathName !== pathArray[1]) {
      this.setState({ pathName: pathArray[1] })
    }

    if (this.props.employeeData !== prevProps.employeeData) {
      this.setState({
        employeeData: this.props.employeeData,
      })
    }
    if (this.props.eventData !== prevProps.eventData) {
      if (this.props.eventData?.link != null) {
        this.setState({
          displayText: "Get My Affiliate Link",
        })
      } else {
        this.setState({
          displayText: "Earn 10% as an Affiliate",
        })
      }
    }
  }
  openAffiliateModal = () => {
    this.setState({ isAffiliateModalOpen: true })
  }

  closeAffiliateModal = () => {
    this.setState({ isAffiliateModalOpen: false })
  }

  closeSuccessModal = () => {
    this.setState({ isContactUsSuccessModal: false })
  }

  closeContactUsModal = () => {
    this.setState({ isContactUsModal: false })
  }

  saveContactUs = () => {
    this.setState({ isContactUsModal: false, isContactUsSuccessModal: true })
  }

  render() {
    return (
      <>
        <div className="footer-portal">
          {this.state.isContactUsSuccessModal ? (
            <ContactUsSuccessMoal
              isOpen={this.state.isContactUsSuccessModal}
              onHide={this.closeSuccessModal}
            />
          ) : (
            ""
          )}
          {this.state.isContactUsModal ? (
            <ContactUsModal
              isOpen={this.state.isContactUsModal}
              onHide={this.closeContactUsModal}
              onSaved={this.saveContactUs}
            />
          ) : (
            ""
          )}

          <Affiliates
            isOpen={this.state.isAffiliateModalOpen}
            onHide={this.closeAffiliateModal}
          />
          <div className="footer--menu">
            <div className="footer-zazafestival-menu">
              {this.state.employeeData != null ? (
                <a
                  href="https://contactus.ciright.com/"
                  
                  // onClick={() => {
                  //   this.setState({ isContactUsModal: true })
                  // }}
                >
                  <p className="portal-sponsers-text">Contact Us</p>
                </a>
              ) : (
                <Link to="/authentication/my-account">
                  <p className="portal-sponsers-text">Contact Us</p>
                </Link>
              )}
              <Link to="/terms-of-use">
                <p className="portal-sponsers-text">Terms & Conditions</p>
              </Link>
            </div>
          </div>
          <div className="portalApp-footer-img">
            {/* <Link to="/">
              <img
                src={
                  AppConfig.API.APP_ID === 2836
                    ? require("../../assets/images/CirightLive/ciright-live-logo.png")
                    : require("../../assets/images/veuit/TV-4.png")
                }
                alt="portal"
              />
            </Link> */}

            {AppConfig.API.APP_ID === 2836 ? (
              <Link to="/">
                <img
                  src={require("../../assets/images/CirightLive/ciright-live-logo.png")}
                  alt="ciright"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={require("../../assets/images/veuit/TV-4.png")}
                  alt="ciright"
                  height="150px"
                  width="150px"
                />
              </Link>
            )}

            <div className="footer-iconsset">
              <Link
                target=""
                to={{
                  pathname: AppConfig.API.X_LINK,
                }}
              >
                <img
                  src={require("../../assets/images/Portal/X.png")}
                  alt="x-icon"
                />
              </Link>
              <Link
                target="_blank"
                to={{
                  pathname: AppConfig.API.LINKDIN_LINK,
                }}
              >
                <img
                  src={require("../../assets/images/Portal/linkedin.png")}
                  alt="linkedin"
                />
              </Link>
              <Link
                target="_blank"
                to={{
                  pathname: AppConfig.API.TIKTOK_LINK,
                }}
              >
                <img
                  src={require("../../assets/images/Portal/tiktoc.png")}
                  alt="tiktoc"
                />
              </Link>
              <Link
                target="_blank"
                to={{
                  pathname: AppConfig.API.INSTA_LINK,
                }}
              >
                <img
                  src={require("../../assets/images/Portal/instagram.png")}
                  alt="instagram"
                />
              </Link>
            </div>
          </div>
          <div className="portal-footer-last"></div>
        </div>
        {/* <div className="addciright-logo">
          <Link target="_blank" to={{ pathname: "https://www.ciright.com/" }}>
            <img
              src={require("../../assets/images/ciright.png")}
              alt="ciright"
            />
          </Link>
        </div> */}
      </>
    )
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FooterManu))
