import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OverlayTrigger } from "react-bootstrap";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

const renderTooltip = (description) => (
  <div className="sponsor-tooltip">
    <p className="sponsor-tooltip-content">{description}</p>
  </div>
);

export class SponsorsBenefitingOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      isTooltipVisible: false,
      sponsorsMap: [],
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getSponsorsList();
  }

  getSponsorsList = async () => {
    try {
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
      };
      const res = await UserService.getSponsorsList(payload);
      if (res.error != null) {
        if (
          res.error.response != null &&
          res.error.response.data != null &&
          res.error.response.data.message != null
        ) {
          NotificationManager.error("", res.error.response.data.message, 3000);
        } else if (res.error.message != null) {
          NotificationManager.error("", "Could not get Sponsors.", 3000);
        }
        this.setState({ isLoading: 0, sponsorsMap: [] });
      } else {
        if (res["status"]) {
          const data = res["data"];
          if (data === null) {
            NotificationManager.error("", "Could not get Sponsors.", 3000);
            this.setState({ isLoading: 0, sponsorsMap: [] });
          } else {
            this.setState({ sponsorsMap: data, isLoading: 0 });
          }
        } else {
          NotificationManager.error("", "Could not get Sponsors.", 3000);
          this.setState({ isLoading: 0, sponsorsMap: [] });
        }
      }
    } catch (err) {
      this.setState({ isLoading: 0, sponsorsMap: [] });
    }
  };

  render() {
    return this.state.isLoading === 1 ? (
      <Loader showLogo />
    ) : (
      <div className="our-sponsors">
        {this.state.sponsorsMap != null && this.state.sponsorsMap.length > 0 ? (
          <>
            <h1>Our Sponsors</h1>
            <div className="brand-section">
              {this.state.sponsorsMap.map((item, index) => {
                return (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={
                      item.description != null && item.description !== ""
                        ? renderTooltip(item.description)
                        : null
                    }
                    // show={this.state.isTooltipVisible}
                    key={index}
                  >
                    <Link
                      className="brand-logo-box"
                      target="_blank"
                      to={{ pathname: item.brandUrl }}
                      // onClick={() =>
                      //   this.setState({
                      //     isTooltipVisible: !this.state.isTooltipVisible,
                      //   })
                      // }
                    >
                      <img
                        src={item.brandLogo}
                        className="brand-logoimg"
                        alt="logo"
                      />
                      <p>
                        {item.brandName != null &&
                        item.brandName !== "" &&
                        item.brandName.length > 16
                          ? item.brandName.substring(0, 16)
                          : item.brandName}
                      </p>
                    </Link>
                  </OverlayTrigger>
                );
              })}
            </div>
          </>
        ) : (
          ""
        )}

        <h1 className="support-of">In Support of</h1>
        <div className="support-section">
          <div className="support-logo-box">
            <img
              src={require("../../assets/images/org.png")}
              className="support-logoimg"
              alt="logo"
            />
          </div>
          <div className="support-logo-box">
            <img
              src={require("../../assets/images/org.png")}
              className="support-logoimg"
              alt="logo"
            />
          </div>
          <div className="support-logo-box">
            <img
              src={require("../../assets/images/org.png")}
              className="support-logoimg"
              alt="logo"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorsBenefitingOrganization);
