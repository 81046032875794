import React from "react";
import { Form } from "react-bootstrap";

const contact = () => {
  return (
    <main className="portal-contact-container">
      <div className="portal-contactinfo">
        <section className="contact-form-section">
          <h2>CONTACT US</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input type="text" placeholder="Name" className="contact-field" />
            <input type="text" placeholder="Title" className="contact-field" />
            <input type="email" placeholder="Email" className="contact-field" />
            <Form.Control
              aria-label="Default select example"
              as={"select"}
              className="contact-field"
              id="contact-select-field"
            >
              <option>Subject</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Control>
            <textarea placeholder="Message" className="contact-message" />
            <div className="contact-send-btn">
              <div className="contact-submit-calculate">
                <p>6 + 4 =</p>
                <input
                  type="text"
                  className="number-addfield"
                />
              </div>
              <button type="submit" className="send-btn1">
                SEND
              </button>
            </div>
          </form>
        </section>
        <section className="contact-imgsection">
          <img
            src={require("../../assets/images/Portal/portal-banner.png")}
            className="conatct-portal-banner"
            alt="portal-banner"
          ></img>
        </section>
      </div>
      <div>
        <img
          src={require("../../assets/images/Portal/world-background.png")}
          className="conatct-portal-bottom-img"
          alt="portal-banner"
        ></img>
      </div>
    </main>
  );
};

export default contact;
