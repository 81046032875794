import { Switch, Route } from "react-router-dom";
import Error from "../views/Error/Error";
import MyAccount from "../views/my-account/my-account";
import MerchantRegistration from "../views/my-account/merchant-registration";
import ForgotPassword from "../views/my-account/forgot-password";
import ResetPassword from "../views/my-account/reset-password";
import SetPassword from "../views/my-account/set-password"
import Ticket from "../views/apple-wallet-ticket/ticket";
import alertbox from "../views/alertbox/alertbox";

const AuthenticationRoute = () => {
  return (
    <Switch>
      <Route path="/authentication/my-account" component={MyAccount} />
      <Route path="/authentication/merchant-registration" component={MerchantRegistration} />
      <Route
        path="/authentication/forgot-password"
        component={ForgotPassword}
      />
      <Route path="/authentication/set-password" component={SetPassword} />
      <Route path="/authentication/reset-password" component={ResetPassword} />
      <Route path="/authentication/ticket/:ticketCode" component={Ticket} />
      <Route path="/authentication/alertbox" component={alertbox} />     
      <Route path="*" component={Error} />
    </Switch>
  );
};

export default AuthenticationRoute;
