import React from "react";
import AuthenticationRoute from "../router/authentication-route";
import HeaderManu from "../components/header/header-manu";

export class AuthenticationLayout extends React.Component {
    render () {
        return (
            <div>
                <div className="appbgimage-opacity">
                    <div className="app-bgimage"></div>
                    <div className="todays-portal-home">
                        <HeaderManu />
                        <AuthenticationRoute />
                    </div>
                </div>
            </div>
        );
    }
}

export default AuthenticationLayout;
