import React, { useEffect, useState } from "react";
import "./BuyTickets.css";
import AppConfig from "../../config/app.config";
import { Link } from "react-router-dom";
const BuyTickets = () => {
  const [eventDetails, setEventDetails] = useState({
    Id: "",
    Name: "",
    Price: "",
    date: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("eventData") != null) {
      const eventData = JSON.parse(localStorage.getItem("eventData"));

      const eventDate = new Date(eventData.eventdate);
      const formattedDate = eventDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      const formattedTime = eventDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      setEventDetails({
        Id: eventData.eventId,
        Name: eventData.eventName,
        date: `${formattedDate} ${formattedTime}`,
        Price:
          eventData.price != null && eventData.price >= 0
            ? eventData.price
            : 3.99,
      });
    } else {
      const eventDate = new Date("11/14/2024 18:00:00");
      const formattedDate = eventDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      const formattedTime = eventDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      setEventDetails({
        Id: -1,
        Name: "Ciright Live Stream",
        date: `${formattedDate} ${formattedTime}`,
        Price: 3.99,
      });
    }
  }, []);
  return (
    <div className="buytickets-container">
      <div className="ticket-card" id="portals-ticketcard">
        {AppConfig.API.IS_ANNOUNCEMENT !== 0 ? (
          <>
            <h1 className="artist-name" style={{ color: "#fff" }}>
              {AppConfig.API.EVENT_HOST_NAME}
            </h1>
          </>
        ) : (
          ""
        )}
        {/* <p className="event-details">{eventDetails.Name}</p> */}
        {AppConfig.API.APP_ID === 2838 ? (
          <>
            <p className="event-details"> MJ Streaming LIVE from Las Vegas</p>
            <p className="event-date"> August 27, 2024 3:00 PM EST </p>
            {/* {eventDetails?.date} */}
          </>
        ) : (
          <>
            {AppConfig.API.EVENT_ID === 2980039 ? (
              <>
                <p className="event-details">
                  {" "}
                  Streaming LIVE from Philadelphia Portal
                </p>
                <p className="event-date"> {eventDetails?.date} PM EST</p>
              </>
            ) : (
              <>
                <p className="event-details">
                  {" "}
                  Streaming LIVE from O'Connell Street in Dublin Ireland
                </p>
                {/* {eventDetails?.date} */}
                <p className="event-date">August 27, 2024 3:00 PM EST</p>
              </>
            )}
          </>
        )}

        <div className="ticket-info">
          {AppConfig.API.EVENT_ID === 2980039 ? (
            <div className="ticket-details">
              <p className="ticket-type">Live Stream </p>
            </div>
          ) : (
            <div className="ticket-details">
              <p className="ticket-type">Live Stream Ticket</p>
              <p className="ticket-subtext">(includes Replay)</p>
            </div>
          )}

          <p className="ticket-price">
            {" "}
            {eventDetails.Price != null && eventDetails.Price > 0
              ? "$" + eventDetails.Price
              : ""}
          </p>
        </div>

        <p className="replay-info">(Includes Concert Replay)</p>
        <Link to="/checkout-ticket" className="buyticket-btns">
          <button className="order-button">
            {" "}
            {AppConfig.API.EVENT_ID === 2980039 ? "Live Stream" : "ORDER NOW"}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BuyTickets;
