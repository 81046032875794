import { combineReducers } from 'redux';
import Mode from './mode/rtlmode';
import signUpReducer from './signUp/signUp.reducer';
import signInReducer from './signIn/signIn.reducer';
import homeReducer from './home/home.reducer';

export const rootReducer = combineReducers({
  mode: Mode,
  signUp: signUpReducer,
  signIn: signInReducer,
  home: homeReducer,
});
