import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AppConfig from "../../config/app.config";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Temrsofuse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className="portal-privacypolicy select-none"
        onCopy={() => {}}
        onPaste={() => {}}
        onCut={() => {}}
      >
        {/* <h6>Terms & Conditions</h6> */}
        <h6>
          {" "}
          {AppConfig.API.APP_ID === 2836 ? "Ciright" : "Veuit"} Live Performance
          Terms of Acceptance Agreement
        </h6>
        {/* <div className="privacy-contact">
          <p>
            <span className="add-info">Contact us</span> with any questions or
            concerns regarding our Terms of Use.
          </p>
        </div> */}
        <div className="heder-margin">
          <div>
            <span className="header-titile">Company Name:</span> Ciright, Inc.
          </div>
          <div>
            <span className="header-titile">Service Name:</span> Ciright Live
          </div>
          <div>
            <span className="header-titile">Corporate Status:</span> A Delaware
            Corporation
          </div>
          <div>
            <span className="header-titile">Service Provided:</span> Live
            streaming of music performances and post-performance video access.
          </div>
        </div>
        <div>
          <div>
            <h5>1. Introduction</h5>
            <p>
              This Terms of Acceptance Agreement ("Agreement") is a legally
              binding document between you, the user ("User," "You," or
              "Customer"), and Ciright, Inc. ("Company," "We," "Our," or "Us").
              By purchasing access to any live music performance or event
              ("Event") hosted by Ciright Live, you acknowledge and agree to be
              bound by the terms and conditions set forth herein. If you do not
              agree to these terms, you must not purchase or access the
              services. IMPORTANT: All sales are final, non-cancelable, and
              non-refundable. This policy is strictly enforced and is in
              accordance with New York State law.
            </p>
            <p>
              <span className="add-info">IMPORTANT:</span> All sales are final,
              non-cancelable, and non-refundable. This policy is strictly
              enforced and is in accordance with New York State law.
            </p>
          </div>
          <div>
            <h5>2. Acknowledgment and Acceptance</h5>
            <p>
              <span className="header-titile"> 2.1 Finality of Sales:</span> By
              purchasing access to any Event, you explicitly acknowledge that
              all sales are final. Under no circumstances will refunds, credits,
              or exchanges be issued for any reason, including but not limited
              to technical difficulties, dissatisfaction with the Event, or
              inability to view the Event live.
            </p>
            <p>
              <span className="header-titile">2.2 Legal Compliance:</span> This
              Agreement is governed by the laws of the State of New York. Your
              acceptance of these terms and your purchase of access to any Event
              constitutes a binding contract enforceable under New York law.
            </p>
            <p>
              <span className="header-titile"> 2.3 Service Description:</span>{" "}
              The Company provides a live streaming service that allows Users to
              view musical performances in real time. Additionally, Users have
              the ability to access the recorded video of the performance after
              the live Event has concluded.
            </p>
          </div>
          <div>
            <h5>3. Performance and Service Quality</h5>
            <p>
              <span className="header-titile"> 3.1 Streaming Quality:</span> The
              Company commits to providing a high-quality streaming experience,
              utilizing advanced data centers and networking technology. Video
              quality, audio bitrate, and data transmission are logged and
              monitored at the second to ensure consistency and reliability.
            </p>
            <p>
              <span className="header-titile"> 3.2 Documentation:</span> The
              Company maintains comprehensive records of IP access, video
              quality, and audio bitrate for each User during the Event. These
              logs are kept to demonstrate the quality of service provided and
              to protect the Company against claims of poor service or refund
              requests.
            </p>
          </div>
          <div>
            <h5>4. Technical and Logistical Support</h5>
            <p>
              <span className="header-titile"> 4.1 Technical Issues:</span>{" "}
              While We strive to deliver a flawless streaming experience, We do
              not guarantee that the Event will be free from interruptions,
              delays, or other technical issues. Users are encouraged to ensure
              their internet connection is stable and sufficient for live
              streaming.
            </p>
            <p>
              <span className="header-titile">
                4.2 No Refunds for Technical Difficulties:
              </span>{" "}
              In the event that You experience technical difficulties, including
              but not limited to, buffering, latency, or inability to connect to
              the stream, You acknowledge and agree that no refund, partial or
              full, will be issued.
            </p>
            <p>
              <span className="header-titile">4.3 Post-Event Access:</span>{" "}
              Should you experience any difficulties during the live stream, you
              will have access to the recorded version of the Event, which you
              may view at your convenience. Access to the recorded Event does
              not entitle you to any refund or credit.
            </p>
          </div>
          <div>
            <h5>5. Intellectual Property</h5>
            <p>
              <span className="header-titile">5.1 Content Ownership:</span> All
              content streamed via Ciright Live, including but not limited to,
              video, audio, graphics, and text, is the intellectual property of
              the Company or its licensors. Unauthorized distribution,
              reproduction, or use of the content is strictly prohibited.
            </p>
            <p>
              <span className="header-titile">5.2 Access Rights:</span> Your
              purchase grants you a limited, non-transferable, non-exclusive
              right to view the Event. You are not permitted to download,
              reproduce, share, or distribute the content in any form without
              prior written consent from the Company.
            </p>
          </div>
          <div>
            <h5>6. Limitation of Liability</h5>
            <p>
              <span className="header-titile">6.1 No Warranties:</span> The
              Company provides the service on an &quot;as-is&quot; and &quot;as
              available&quot; basis. We make no warranties, express or implied,
              regarding the service&#39;s performance, quality, or availability.
            </p>
            <p>
              <span className="header-titile">
                6.2 Limitation of Liability:
              </span>{" "}
              To the maximum extent permitted by law, the Company shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, including but not limited to, loss of data,
              revenue, or profit, resulting from your use or inability to use
              the service.
            </p>
            <p>
              <span className="header-titile">6.3 Maximum Liability:</span> In
              no event shall the Company&#39;s total liability to You for any
              damages, losses, or causes of action exceed the amount paid by You
              for the Event.
            </p>
          </div>
          <div>
            <h5>7. Fraud Prevention and Consumer Protection</h5>
            <p>
              <span className="header-titile">7.1 Anti-Fraud Measures:</span>{" "}
              The Company has implemented comprehensive anti-fraud measures,
              including tracking IP addresses, monitoring transaction patterns,
              and maintaining detailed logs of data transfers to prevent and
              detect organized refund schemes.
            </p>
            <p>
              <span className="header-titile">
                7.2 Consumer Protection Compliance:
              </span>{" "}
              The Company adheres to all applicable consumer protection laws and
              regulations. However, this Agreement&#39;s non-refundable policy
              is strictly enforced, and We reserve the right to challenge any
              chargeback or refund request through legal channels.
            </p>
            <p>
              <span className="header-titile">7.3 Chargeback Policy:</span> Any
              chargeback initiated by a User will result in immediate suspension
              of access to Ciright Live services. The Company reserves the right
              to contest any chargebacks with full documentation and pursue
              legal action to recover any losses incurred.
            </p>
          </div>
          <div>
            <h5>8. Governing Law and Dispute Resolution</h5>
            <p>
              <span className="header-titile">8.1 Governing Law:</span> This
              Agreement shall be governed by and construed in accordance with
              the laws of the State of New York, without regard to its conflict
              of law principles.
            </p>
            <p>
              <span className="header-titile">8.2 Dispute Resolution:</span> Any
              disputes arising out of or relating to this Agreement or the
              service provided by Ciright Live shall be resolved through binding
              arbitration in New York, New York, in accordance with the rules of
              the American Arbitration Association.
            </p>
            <p>
              <span className="header-titile">8.3 Class Action Waiver:</span>{" "}
              You agree to waive any right to participate in a class action
              lawsuit or class-wide arbitration against the Company.
            </p>
          </div>
          <div>
            <h5>9. Amendments and Modifications</h5>
            <p>
              <span className="header-titile">9.1 Right to Amend:</span> The
              Company reserves the right to amend or modify this Agreement at
              any time, with or without notice. Any such amendments will be
              posted on the Ciright Live website, and Your continued use of the
              service constitutes acceptance of the amended terms.
            </p>
            <p>
              <span className="header-titile">9.2 Severability:</span> If any
              provision of this Agreement is found to be invalid or
              unenforceable, the remaining provisions shall remain in full force
              and effect.
            </p>
          </div>
          <div>
            <h5>10. Entire Agreement</h5>
            <p>
              This Agreement constitutes the entire understanding between You
              and the Company with respect to Your purchase and use of the
              Ciright Live service and supersedes all prior or contemporaneous
              agreements, understandings, or representations.
            </p>
          </div>
          <div>
            <h5>11. Contact Information</h5>
            <p>
              For any questions or concerns regarding this Agreement, please
              contact Us at: Ciright.com – Contact us section of the website.
            </p>
          </div>
          <div className="accept-condi">
            BY PURCHASING ACCESS TO ANY EVENT PROVIDED BY CIRIGHT LIVE, YOU
            HEREBY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE
            BOUND BY THIS AGREEMENT. YOU FURTHER AGREE THAT ALL SALES ARE FINAL
            AND NON-REFUNDABLE, AS STATED ABOVE.
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Temrsofuse);
