import SignUpActionTypes from "./signUp.types";

export const userSignup = (payload) => ({
  type: SignUpActionTypes.SIGNUP_START,
  payload,
});

export const userSignupSuccess = (payload) => ({
  type: SignUpActionTypes.SIGNUP_SUCCESS,
  payload,
});

export const userSignupFailure = (payload) => ({
  type: SignUpActionTypes.SIGNUP_FAILURE,
  payload,
});

export const merchantSignup = (payload) => ({
  type: SignUpActionTypes.MERCHANT_SIGNUP_START,
  payload,
});

export const merchantSignupSuccess = (payload) => ({
  type: SignUpActionTypes.MERCHANT_SIGNUP_SUCCESS,
  payload,
});

export const merchantSignupFailure = (payload) => ({
  type: SignUpActionTypes.MERCHANT_SIGNUP_FAILURE,
  payload,
});
