import React from "react";
import { connect } from "react-redux";
import { selectemployeeData } from "../../store/home/home.selectors";
import { bindActionCreators } from "redux";
import { setEmployeeData } from "../../store/home/home.actions";
import ReplayPlayer from "../../components/video/ReplayPlayer";
import "./watchlist.css";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import Loader from "../../components/loader/loader";
import { NotificationManager } from "react-notifications";
import { Modal, Form } from "react-bootstrap";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setEmployeeData }, dispatch),
});

class Watchlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplayPlayer: false,
      employeeData: null,
      eventData: null,
      isLoading: 1,
      isVideo: 0,
      videoUrl: "",
      eventImage: "",
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var employeeData = this.props.employeeData;
    if (employeeData != null) {
      this.setState({
        employeeData: employeeData,
      });
      this.getEventData(employeeData.employeeId);
    } else {
      this.props.history.push("/");
    }
  }

  getEventData = (employeeId) => {
    this.setState({ isLoading: 1 });
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const payload = {
      loginEmployeeId: employeeId,
      timeZone: timeZone,
      appId: AppConfig.API.APP_ID,
    };
    UserService.getWatchlistEventData(payload)
      .then(async (res) => {
        if (res.error != null) {
          NotificationManager.success(
            res.error?.response?.data?.message ?? "Somthing went wrong!.",
            "",
            2000
          );
          this.setState({ isLoading: 0 });
          this.props.history.push("/");
        } else {
          if (res["status"]) {
            if (res["data"] != null && res["data"].length) {
              this.setState({
                isLoading: 0,
                eventData: res["data"],
              });
            } else {
              NotificationManager.success(
                "There is no any wacthlist.",
                "",
                2000
              );
              this.setState({ isLoading: 0 });
              this.props.history.push("/");
            }
          } else {
            NotificationManager.error(res["message"], "", 2000);
            this.setState({ isLoading: 0 });
            this.props.history.push("/");
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "", 2000);
        this.setState({ isLoading: 0 });
        this.props.history.push("/");
      });
  };

  handleShow = () => {
    if (
      this.state.eventData[0].eventLink != null &&
      this.state.eventData[0].eventLink !== ""
    ) {
      this.setState({
        showReplayPlayer: true,
        videoUrl: this.state.eventData[0].eventLink,
        eventImage: this.state.eventData[0].eventImage,
      });
      //   this.setState({
      //     showReplayPlayer: true,
      //     videoUrl:
      //       "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8",
      //   });
    } else {
      this.setState({ isVideo: 1 });
    }
  };

  handleClose = () => {
    this.setState({ showReplayPlayer: false });
  };

  render() {
    const { showReplayPlayer } = this.state;

    return (
      <>
        {this.state.isLoading === 1 ? (
          <Loader />
        ) : (
          <>
            <Modal
              show={this.state.isVideo}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              id="video-modal"
              className="create-acoount-content"
            >
              <div
                className="portal-accountcreation-left"
                id="addtocart-modal-account"
              >
                <div className="portal-modalclose">
                  <Closeicon
                    className="close-icon"
                    onClick={() => {
                      this.setState({ isVideo: 0 });
                    }}
                  />
                </div>
                <div
                  className="create-acoount-content"
                  id="Cust-Info"
                  style={{ textAlign: "center", marginTop: "2rem" }}
                >
                  <h3>Video comming Soon...</h3>
                </div>
              </div>
            </Modal>
            {!showReplayPlayer ? (
              <div className="portals-network">
                <div className="watchlist-main">
                  <p className="watchlist-title-text">PURCHASED</p>
                  {this.state.eventData[0].eventImage != null &&
                  this.state.eventData[0].eventImage !== "" ? (
                    <img
                      src={this.state.eventData[0].eventImage}
                      alt="Replay Thumbnail"
                      onClick={this.handleShow}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    ""
                  )}

                  <p className="watchlist-subtitle-text">
                    {AppConfig.API.EVENT_NAME}
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  height: "100vh",
                  width: "100vw",
                  zIndex: 999999,
                }}
              >
                <ReplayPlayer
                  videoTitle={AppConfig.API.EVENT_HOST_NAME} //"Cian Ducrot – Pop-up Show"
                  videoSubtitle={AppConfig.API.EVENT_PLACE}
                  videoUrl={this.state.videoUrl}
                  isHeaderVisible={true}
                  onClose={this.handleClose}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist);
