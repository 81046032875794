import { useEffect, useState } from "react";
import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Loader from "../loader/loader";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  // const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [loadErrorMessage, setLoadErrorMessage] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          NotificationManager.warning("Payment succeeded!", "", 2000);
          setMessage("Payment succeeded!");
          break;
        case "processing":
          NotificationManager.warning("Your payment is processing.", "", 2000);
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          NotificationManager.warning(
            "Your payment was not successful, please try again.",
            "",
            2000
          );
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          NotificationManager.warning("Something went wrong.", "", 2000);
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, props.orderId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: props.returnUrl,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      NotificationManager.warning(error.message, "", 2000);
      setMessage(error.message);
    } else {
      NotificationManager.warning("An unexpected error occurred.", "", 2000);
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      /> */}
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onLoadError={(e) => {
          setLoadErrorMessage("ERROR WHILE INITIATING PAYMENT");
          setIsInitializing(false);
        }}
        onLoaderStart={(e) => {
          console.log(e);
        }}
        onReady={() => {
          setIsInitializing(false);
        }}
      />
      {(isLoading || isInitializing) && <Loader />}
      {!isInitializing && (
        <>
          {loadErrorMessage == null ? (
            <>
              <div className="stripe-checkoutbtn">
                <button
                  className="stripeButton"
                  disabled={isLoading || !stripe || !elements}
                  id="submit"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <div className="spinner" id="spinner"></div>
                    ) : (
                      "Complete Order"
                    )}
                  </span>
                </button>
              </div>
              {message && <div id="payment-message">{message}</div>}
            </>
          ) : (
            <div id="payment-message">{loadErrorMessage}</div>
          )}
        </>
      )}
      {/* {props.subTotal != null && props.totalConvenienceFees != null ? (
        <div className="stripe-payment-info">
          <div className="stripe-payment-subtotal">
            <h1>Subtotal</h1>
            <h2>${props.subTotal.toFixed(2)}</h2>
          </div>
          <div className="stripe-payment-subtotal">
            <h1>Processing Fees</h1>
            <h2>${props.totalConvenienceFees.toFixed(2)}</h2>
          </div>
          <div className="stripe-payment-subtotal">
            <h1>Tax</h1>
            <h2>
              $
              {(
                ((props.subTotal + props.totalConvenienceFees) *
                  props.taxPercent) /
                100
              ).toFixed(2)}
            </h2>
          </div>
          <div className="stripe-payment-total">
            <h1>Total</h1>
            <h2>
              $
              {(
                props.subTotal +
                props.totalConvenienceFees +
                ((props.subTotal + props.totalConvenienceFees) *
                  props.taxPercent) /
                  100
              ).toFixed(2)}
            </h2>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </form>
  );
}
