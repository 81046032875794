import SignUpActionTypes from "./signUp.types";
// Set the reducers initial state.
const INITIAL_STATE = {
  loading: false,
  errorMessage: null,
  signUpSuccess: null,

  merchantSignUpSuccess: null,
};

// Define scenarios for reducers.
const signUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignUpActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        signUpSuccess: action.payload.signUpSuccess,
        loading: action.payload.loading,
      };
    case SignUpActionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        signUpSuccess: action.payload.signUpSuccess,
        loading: action.payload.loading,
        errorMessage: action.payload.err,
      };

    case SignUpActionTypes.MERCHANT_SIGNUP_SUCCESS:
      return {
        ...state,
        merchantSignUpSuccess: action.payload.merchantSignUpSuccess,
        loading: action.payload.loading,
      };
    case SignUpActionTypes.MERCHANT_SIGNUP_FAILURE:
      return {
        ...state,
        merchantSignUpSuccess: action.payload.merchantSignUpSuccess,
        loading: action.payload.loading,
        errorMessage: action.payload.err,
      };

    default:
      return state;
  }
};

export default signUpReducer;
