import { Switch, Route } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Home from "../views/home/home"
import Ticket from "../views/apple-wallet-ticket/ticket"
import Error from "../views/Error/Error"
import About from "../views/about/about"
import Location from "../views/location/location"
import CheckoutTicket from "../views/buy-ticket/checkout-ticket"
import Referral from "../views/referral-page/referral-page"
import AccountInfo from "../views/account-info/account-info"
import Faq from "../views/footer-page/faq"
import Contact from "../views/footer-page/contact"
import Privacypolicy from "../views/footer-page/privacy-policy"
import Termsofuse from "../views/footer-page/terms-of-use"
import Confirmation from "../views/buy-ticket/payment-confirmation"
import StripeConfirmation from "../views/buy-ticket/stripe-payment-confirmation"
import StripeSponsorConfirmation from "../views/buy-ticket/stripe-sponsor-payment-confirmation"
import SponsorshipOpportunities from "../views/footer-page/sponsorship-opportunities"
import SponsorsBenefitingOrganization from "../views/footer-page/sponsors-benefiting-organization"
import AddCard from "../views/add-card/add-card"
import NewHome from "../views/new-home/new-home"
import AppConfig from "../config/app.config"
import BuyTickets from "../views/buy-tickets/buyTickets"
import MyLink from "../views/my-link/my-link.js"
import Watchlist from "../views/watchlist/watchlist"

const getPath = (path) =>
  AppConfig.API.BASE_PATH ? `${AppConfig.API.BASE_PATH}${path}` : path

const MainRoute = () => {
  return (
    <TransitionGroup
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <CSSTransition
        classNames="fade"
        timeout={300}
      >
        <Switch>
          <Route
            path={getPath("/about")}
            component={About}
          />
            <Route
            path={getPath("/watchlist")}
            component={Watchlist}
          />
          <Route
            path={getPath("/location")}
            component={Location}
          />
          {/* <Route path={getPath("/buy-ticket")} component={BuyTicket} /> */}
          <Route
            path={getPath("/buy-ticket")}
            component={BuyTickets}
          />
          <Route
            path={getPath("/checkout-ticket")}
            component={CheckoutTicket}
          />
          <Route
            path={getPath("/ticket/:smartContractId/:eventId")}
            component={Ticket}
          />
          <Route
            path={getPath("/referral/:code")}
            component={Referral}
          />
          <Route
            path={getPath("/my-link/:userName")}
            component={MyLink}
          />
          <Route
            path={getPath("/account-info")}
            component={AccountInfo}
          />
          <Route
            path={getPath("/faq")}
            component={Faq}
          />
          <Route
            path={getPath("/contact")}
            component={Contact}
          />
          <Route
            path={getPath("/privacy-policy")}
            component={Privacypolicy}
          />
          <Route
            path={getPath("/terms-of-use")}
            component={Termsofuse}
          />
          <Route
            path={getPath("/payment-confirmation")}
            component={Confirmation}
          />
          <Route
            path={getPath("/stripe-payment-confirmation/:orderId")}
            component={StripeConfirmation}
          />
          <Route
            path={getPath("/stripe-sponsor-payment-confirmation/:orderId")}
            component={StripeSponsorConfirmation}
          />
          <Route
            path={getPath("/sponsorship")}
            component={SponsorshipOpportunities}
          />
          <Route
            path={getPath("/sponsors-benefiting-organization")}
            component={SponsorsBenefitingOrganization}
          />
          <Route
            path={getPath("/add-card")}
            component={AddCard}
          />
          <Route
            path={getPath("/new-home")}
            component={NewHome}
          />
          <Route
            path={getPath("/")}
            exact
            component={Home}
          />
          <Route
            path="*"
            component={Error}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default MainRoute
