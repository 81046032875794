import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion, Card } from "react-bootstrap";
import { ReactComponent as Faqdown } from "../../assets/images/arrow-faq.svg";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/loader/loader";
import ContextAwareAccordionToggle from "../../components/context_aware_accordion_toggle";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      faqs: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getFaqs();
  }

  getFaqs = async () => {
    try {
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        sphereUrl: AppConfig.API.SPHERE_TYPE_URL,
      };
      const res = await UserService.getFaqs(payload);
      if (res.error != null) {
        if (
          res.error.response != null &&
          res.error.response.data != null &&
          res.error.response.data.message != null
        ) {
          NotificationManager.error("", res.error.response.data.message, 3000);
        } else if (res.error.message != null) {
          NotificationManager.error("", "Could not get FAQs.", 3000);
        }
        this.setState({ isLoading: 0 });
      } else {
        if (res["status"]) {
          const data = res["data"];
          if (data === null) {
            NotificationManager.error("", "Could not get FAQs.", 3000);
          } else {
            this.setState({ faqs: res["data"][0]?.faqsList, isLoading: 0 });
          }
        } else {
          NotificationManager.error("", "Could not get FAQs.", 3000);
          this.setState({ isLoading: 0 });
        }
      }
    } catch (err) {
      this.setState({ isLoading: 0 });
    }
  };

  render() {
    return this.state.isLoading === 1 ? (
      <Loader showLogo />
    ) : (
      <div className="portal-faq">
        <h4>Portal FAQs</h4>
        <Accordion>
          {this.state.faqs != null ? (
            this.state.faqs.map((faq, index) => {
              return (
                <Card key={index}>
                  <Card.Header>
                    <ContextAwareAccordionToggle
                      variant="link"
                      eventKey={`${index}`}
                      className="faq-toggle"
                    >
                      <div className="faq-questions">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: faq.question,
                          }}
                        />
                        <Faqdown id="faqdown-icon" />
                      </div>
                    </ContextAwareAccordionToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`${index}`}>
                    <Card.Body>
                      {" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: faq.answer,
                        }}
                      />{" "}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })
          ) : (
            <p style={{ textAlign: "center", fontSize: "30px" }}>
              No FAQs Found
            </p>
          )}
        </Accordion>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
