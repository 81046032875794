import { createSelector } from "reselect";

const selectSignUp = (state) => state.signUp;

export const selectSignUpStatus = createSelector([selectSignUp], (signUp) => {
  return {
    success: signUp.signUpSuccess,
    loading: signUp.loading,
    error: signUp.errorMessage,
  };
});

export const selectMerchantSignUpStatus = createSelector(
  [selectSignUp],
  (signUp) => {
    return {
      success: signUp.merchantSignUpSuccess,
      loading: signUp.loading,
      error: signUp.errorMessage,
    };
  }
);
