import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import validator from "validator";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  merchantSignup,
  merchantSignupFailure,
} from "../../store/signUp/signUp.actions";
import { selectMerchantSignUpStatus } from "../../store/signUp/signUp.selectors";
import SignupSuccessModal from "../../components/model/signup-success-modal";
import Loader from "../../components/loader/loader";

const mapStateToProps = (state) => {
  return {
    selectMerchantSignUpStatus: selectMerchantSignUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      merchantSignup,
      merchantSignupFailure,
    },
    dispatch
  ),
});

const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");
var isNotifiaction = 0;

export class MerchantRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordProgress: 0,
      repeatedPassword: "",
      isDisabled: "",
      passwordType: "password",
      repeatedPasswordType: "password",

      isSignUpModalVisible: false,
      isSuccessModalVisible: false,
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleRepeatedPassword = this.handleRepeatedPassword.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectMerchantSignUpStatus, merchantSignupFailure } = this.props;
    if (isNotifiaction === 1) {
      if (selectMerchantSignUpStatus.success !== null) {
        if (selectMerchantSignUpStatus.success) {
          NotificationManager.success(
            "Merchant Registration Successfully.",
            "",
            2000
          );
          isNotifiaction = 0;
          this.timeoutHandle = setTimeout(() => {
            this.setState({
              isLoading: 0,
              email: "",
              phone: "",
              firstName: "",
              lastName: "",
              password: "",
              passwordProgress: 0,
              repeatedPassword: "",
              isDisabled: "",
              passwordType: "password",
              repeatedPasswordType: "password",
              isSuccessModalVisible: true,
            });
          }, 3000);
          merchantSignupFailure({
            merchantSignUpSuccess: false,
            loading: false,
            err: null,
          });
        } else {
          if (selectMerchantSignUpStatus.error !== null) {
            if (selectMerchantSignUpStatus.error !== "") {
              merchantSignupFailure({
                merchantSignUpSuccess: false,
                loading: false,
                err: null,
              });
              NotificationManager.error(
                selectMerchantSignUpStatus.error,
                "",
                2000
              );
              isNotifiaction = 0;
              this.timeoutHandle = setTimeout(() => {
                this.setState({
                  isLoading: 0,
                  email: "",
                  phone: "",
                  firstName: "",
                  lastName: "",
                  password: "",
                  passwordProgress: 0,
                  repeatedPassword: "",
                  isDisabled: "",
                });
              }, 3000);
            }
            isNotifiaction = 0;
          }
        }
      }
    }
  }

  handleEmail(event) {
    this.setState({ email: removeExtraSpace(event.target.value) });
  }
  handlePhone(event) {
    const phoneNumber = event.target.value.replace(/\D/g, "");
    let formatted = "";
    if (phoneNumber.length > 3) {
      formatted += phoneNumber.substr(0, 3) + "-";
      if (phoneNumber.length > 6) {
        formatted += phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
      } else {
        formatted += phoneNumber.substr(3);
      }
    } else {
      formatted = phoneNumber;
    }
    this.setState({ phone: formatted });
  }
  handleFirstName(event) {
    this.setState({ firstName: removeExtraSpace(event.target.value) });
  }
  handleLastName(event) {
    this.setState({ lastName: removeExtraSpace(event.target.value) });
  }
  handlePassword(event) {
    const pass = event.target.value;
    const hasLength = pass.length >= 8 ? 1 : 0;
    const hasUppercase = /[A-Z]/.test(pass) ? 1 : 0;
    const hasLowercase = /[a-z]/.test(pass) ? 1 : 0;
    const hasNumber = /[0-9]/.test(pass) ? 1 : 0;
    const progress =
      ((hasLength + hasUppercase + hasLowercase + hasNumber) / 4) * 100;
    this.setState({
      password: event.target.value,
      passwordProgress: progress,
    });
  }
  handleRepeatedPassword(event) {
    this.setState({ repeatedPassword: event.target.value });
  }

  signUp = () => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const rule = new RegExp(/^[a-zA-Z ]{2,20}$/);
    const fNumber = this.state.phone.split("-").join("");
    const { merchantSignup } = this.props;
    if (this.state.firstName.trim() === "") {
      NotificationManager.warning("Please Enter First Name.", "", 2000);
    } else if (!rule.test(this.state.firstName)) {
      NotificationManager.warning(
        "First Name must contains at least two letter with only characters, not allow numaric value.",
        "",
        2000
      );
    } else if (this.state.lastName.trim() === "") {
      NotificationManager.warning("Please Enter Last Name.", "", 2000);
    } else if (!rule.test(this.state.lastName)) {
      NotificationManager.warning(
        "Last Name must contains at least two letter with only characters, not allow numaric value.",
        "",
        2000
      );
    } else if (this.state.email.trim() === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else if (fNumber.trim() === "") {
      NotificationManager.warning("Please Enter Phone.");
    } else if (!pattern.test(fNumber)) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
    } else if (fNumber.length !== 10) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
    } else if (this.state.password === "") {
      NotificationManager.warning("Please Enter Password.", "", 2000);
    } else if (!validator.isStrongPassword(this.state.password)) {
      NotificationManager.warning(
        "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.",
        "",
        2000
      );
    } else if (this.state.repeatedPassword === "") {
      NotificationManager.warning("Please Enter Confirm Password.", "", 2000);
    } else if (this.state.repeatedPassword !== this.state.password) {
      NotificationManager.warning("Password's do not match.", "", 2000);
    } else {
      const payload = {
        name: this.state.firstName + " " + this.state.lastName,
        phone: fNumber,
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.repeatedPassword,
      };

      isNotifiaction = 1;
      this.setState({ isLoading: 1, isDisabled: "disabled" });
      merchantSignup(payload);
    }
  };

  showPassword = () => {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  };

  showRepeatedPassword = () => {
    if (this.state.repeatedPasswordType === "password") {
      this.setState({ repeatedPasswordType: "text" });
    } else {
      this.setState({ repeatedPasswordType: "password" });
    }
  };

  isSignUpValid = () => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const rule = new RegExp(/^[a-zA-Z ]{2,20}$/);
    const fNumber = this.state.phone.split("-").join("");
    if (
      !validator.isEmail(this.state.email) ||
      fNumber.trim() === "" ||
      !pattern.test(fNumber) ||
      fNumber.length !== 10 ||
      this.state.firstName.trim() === "" ||
      !rule.test(this.state.firstName) ||
      this.state.lastName.trim() === "" ||
      !rule.test(this.state.lastName) ||
      this.state.password === "" ||
      !validator.isStrongPassword(this.state.password) ||
      this.state.repeatedPassword === "" ||
      this.state.repeatedPassword !== this.state.password
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 && <Loader />}
        <div className="checkout-white-bg">
          <div className="portal-checkout">
            <h4>Merchant Registration</h4>
            <div
              className="create-return"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div
                className="portal-accountcreation-left"
                id="addtocart-modal"
                style={{
                  marginRight: 0,
                  border: "none",
                  borderRadius: 0,
                  maxWidth: "795px",
                  width : "100%",
                  paddingTop : 0,
                }}
              >
                <div className="create-acoount-content" id="merchant-account">
                  <h5>Create Account</h5>
                  <div className="createacoount-form">
                    <Form.Group>
                      <label className="label">First name</label>
                      <Form.Control
                        type="text"
                        className="mb-0 create-signin"
                        maxLength="20"
                        id="txtFirstname"
                        // placeholder="First Name"
                        value={this.state.firstName}
                        onChange={this.handleFirstName}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <label className="label">LAST NAME</label>
                      <Form.Control
                        type="text"
                        className="mb-0 create-input"
                        maxLength="20"
                        id="txtLastname"
                        // placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={this.handleLastName}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="createacoount-form">
                    <Form.Group>
                      <label className="label">email address</label>
                      <Form.Control
                        type="email"
                        className="mb-0 create-input"
                        id="txtEmail"
                        // placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleEmail}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <label className="label">Mobile Number</label>
                      <Form.Control
                        type="text"
                        className="mb-0 create-input"
                        maxLength="12"
                        id="txtPhone"
                        // placeholder="Phone Number"
                        value={this.state.phone}
                        onChange={this.handlePhone}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="createacoount-form" id="accept-pwd">
                    <Form.Group>
                      <label className="label">Create Password</label>
                      <Form.Control
                        type="password"
                        className="mb-0 create-input"
                        id="txtPassword"
                        // placeholder="Password"
                        value={this.state.password}
                        onChange={this.handlePassword}
                        required
                      />
                    </Form.Group>
                    <p id="accept-terms">
                      Password must be green-striped (strong) to proceed.
                      Requires at least 8 characters, including 1 uppercase
                      letter, 1 lowercase letter, and 1 number.
                    </p>
                  </div>
                  <div className="createacoount-form Password-Strength">
                    <Form.Group>
                      <label className="label">Password Strength</label>
                      <ProgressBar
                        now={this.state.passwordProgress}
                        className={`progress-accountcretaion ${
                          this.state.passwordProgress > 75
                            ? "strong"
                            : this.state.passwordProgress > 50
                            ? "medium"
                            : "weak"
                        }`}
                      />
                    </Form.Group>
                  </div>
                  <div className="createacoount-form">
                    <Form.Group>
                      <label className="label">Confirm Password</label>
                      <Form.Control
                        type="password"
                        className="mb-0 create-input"
                        id="txtRepeatedPassword"
                        // placeholder="Repeat Password"
                        value={this.state.repeatedPassword}
                        onChange={this.handleRepeatedPassword}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="checkout-btn" id="merchant-register-btn">
                    <button
                      className="Create-Account"
                      type="submit"
                      onClick={this.signUp}
                    >
                      Create Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-portalApp-checkout">
          <div className="portalApp-footer-img">
            <p>© 2024 Ciright Live. All rights reserved.</p>
          </div>
        </div>
        <SignupSuccessModal
          show={this.state.isSuccessModalVisible}
          onHide={() => {
            this.setState({
              isSuccessModalVisible: false,
            });
          }}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantRegistration);
