import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Privacypolicy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className="portal-privacypolicy select-none"
        onCopy={() => {}}
        onPaste={() => {}}
        onCut={() => {}}
      >
        <h6>Privacy Policy</h6>
        <div className="privacy-contact">
          <p>
            <span className="add-info">Contact us</span> with any questions or
            concerns regarding our Privacy Policy.
          </p>
        </div>
        <div>
          <div>
            <p>
              <span>This Privacy Statement</span> explains our practices,
              including your choices, regarding the collection, use, and
              disclosure of certain information, including your personal
              information in connection with the Portal service.
            </p>
          </div>
          <div>
            <h4 className="mt-4">Contacting Us</h4>
            <p>
              If you have general questions about your account or how to contact
              customer service for assistance, please visit our Contact Us
              section of our website as well as the FAQ section on our website.
              For questions specifically about this Privacy Statement, or our
              use of your personal information, cookies or similar technologies,
              please contact us from our website.
            </p>
            <p>
              The data controller of your personal information is Portal, Inc.
              Please note that if you contact us to assist you, for your safety
              and ours we may need to authenticate your identity before
              fulfilling your request.
            </p>
          </div>

          <div className="mb-3">
            <h4 className="mt-4">Collection of Information</h4>
            <p>We receive and store information about you such as:</p>

            <ul className="mb-3 list-contact-set">
              <li>
                <b>Information you provide to us:</b> We collect information you
                provide to us which includes:
              </li>

              <ul className="list-contact-set">
                <li>
                  your name, email address, address or postal code, payment
                  method(s), telephone number, and other identifiers you might
                  use. We collect this information in a number of ways,
                  including when you enter it while using our service, interact
                  with our customer service, or participate in surveys or
                  marketing promotions;
                </li>
                <li>
                  information when you choose to provide ratings, taste
                  preferences, account settings (including preferences set in
                  the “Account” section of our website), or otherwise provide
                  information to us through our service or elsewhere.
                </li>
              </ul>

              <li>
                <b>Information we collect automatically:</b>We collect
                information about you and your use of our service, as well as
                information regarding your network, network devices, and your
                computer or other Portal capable devices you might use to access
                our service.
              </li>

              <li>
                <b>Information from other sources: </b> We also obtain
                information from other sources. We protect this information
                according to the practices described in this Privacy Statement,
                plus any additional restrictions imposed by the source of the
                data. These sources vary over time, but could include:
              </li>
              <ul className="mb-3 list-contact-set">
                <li>
                  service providers that help us determine a location based on
                  your IP address in order to customize our service and for
                  other uses consistent with this Privacy Statement;
                </li>
                <li>
                  security service providers who provide us with information to
                  secure our systems, prevent fraud and help us protect the
                  security of Portal accounts;
                </li>
                <li>
                  payment service providers who provide us with payment
                  information, or updates to that information, based on their
                  relationship with you;
                </li>
                <li>
                  online and offline data providers, from which we obtain
                  aggregated demographic, interest based and online advertising
                  related data;
                </li>
                <li>
                  publicly-available sources such as publicly available posts on
                  social media platforms and information available through
                  public databases associating IP addresses with internet
                  service providers (ISPs);
                </li>
                <li>
                  third party services that you are signed into and that provide
                  functions within Portal games, such as multiplayer gameplay,
                  leaderboards, and game saving options.
                </li>
              </ul>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="mt-4">Use of Information</h4>
            <p>
              We use information to provide, analyze, administer, enhance and
              personalize our services and marketing efforts, to process your
              registration, your orders and your payments, and to communicate
              with you on these and other topics.
            </p>

            <ul className="mb-3 list-contact-set">
              <li>
                <b>Disclosure of Information</b> We disclose your information
                for certain purposes and to third parties, as described below:
              </li>
              <ul className="mb-3 list-contact-set">
                <li>
                  <b>The Portal family of companies: </b> We share your
                  information among the Portal family of companies as needed for:
                  providing you with access to our services; providing customer
                  support; making decisions about service improvements; content
                  development; and for other purposes described in the Use of
                  Information section of this Privacy Statement.
                </li>
                <li>
                  <b>Promotional offers: </b> We may offer joint promotions or
                  programs that, in order for your participation, will require
                  us to share your information with third parties. In fulfilling
                  these types of promotions, we may share your name and other
                  information in connection with fulfilling the incentive.
                  Please note that these third parties are responsible for their
                  own privacy practices.
                </li>
                <li>
                  <b>Protection of Portal and others:</b> Portal and its Service
                  Providers may disclose and otherwise use your personal and
                  other information where we or they reasonably believe such
                  disclosure is needed to (a) satisfy any applicable law,
                  regulation, legal process, or governmental request, (b)
                  enforce applicable terms of use, including investigation of
                  potential violations thereof, (c) detect, prevent, or
                  otherwise address illegal or suspected illegal activities
                  (including payment fraud), security or technical issues, or
                  (d) protect against harm to the rights, property or safety of
                  Portal, its users or the public, as required or permitted by
                  law.
                </li>
                <li>
                  <b>Business transfers:</b>In connection with any
                  reorganization, restructuring, merger or sale, or other
                  transfer of assets, we will transfer information, including
                  personal information, provided that the receiving party agrees
                  to respect your personal information in a manner that is
                  consistent with our Privacy Statement.
                </li>
              </ul>
              <li>
                Whenever in the course of sharing information we transfer
                personal information to other countries, we will ensure that the
                information is transferred in accordance with this Privacy
                Statement and as permitted by the applicable laws on data
                protection.
              </li>
              <p>
                You may also choose to disclose your information in the
                following ways:
              </p>

              <ul className="mb-3 list-contact-set">
                <li>
                  certain portions of our service may contain a tool which gives
                  you the option to share information by email, text message and
                  social or other sharing applications, using the clients and
                  applications on your smart device;
                </li>
                <li>
                  social plugins and similar technologies allow you to share
                  information; and
                </li>
                <li>
                  through Portal game features, such as multiplayer options and
                  leaderboards.
                </li>
              </ul>

              <li>
                Social plugins and social applications are operated by the
                social networks themselves, and are subject to their terms of
                use and privacy policies. Similarly, some of the Portal game
                features mentioned above (such as multiplayer gameplay,
                leaderboards, and game saving options) may require use of a
                third-party service, which is subject to that service’s terms of
                use and privacy policy.
              </li>
            </ul>
          </div>

          <div className="mb-3 ">
            <h4 className="mt-4 ">Access to Account and Profiles</h4>
            <ul className="mb-3 list-contact-set">
              <li>
                <b>“Remember me” function: </b> For ease of access to your
                account, you can use the “Remember me on this device” function
                when you log in to the website. This function uses technology
                that enables us to provide direct account access and to help
                administer the Portal service without requiring reentry of any
                password or other user identification when your browser revisits
                the service.
              </li>
              <li>
                <b>Profiles: </b> Profiles allow users to have a distinct,
                personalized Portal experience, built around the movies and shows
                of interest to them, as well as separate watch histories. Please
                note that profiles are available to everyone who uses your Portal
                account, so that anyone with access to your Portal account can
                navigate to and use, edit or delete profiles. You should explain
                this to others with access to your account, and if you do not
                wish them to use or change your profile, be sure to let them
                know. Profile users may be offered the opportunity to add an
                email address, phone number or other information to the profile
                and will be provided with notice of collection and use at the
                time such information is requested (the Profile Privacy Notice
                applies). We have various parental controls available, you can
                learn more by reading our Help article located at{" "}
                <span className="add-linkcolor">
                  https://help.Portal.com/node/264.
                </span>
              </li>
            </ul>
          </div>
          <div className="mb-3 ">
            <h4 className="mt-4 ">Interest-Based Ads.</h4>
            <p>
              Interest-based ads are online ads that are tailored to your likely
              interests based on your use of various apps and websites across
              the Internet. If you are using a browser, then cookies and web
              beacons can be used to collect information to help determine your
              likely interests. If you are using a mobile device, tablet, or
              streaming media device that includes a resettable device
              identifier, then that identifier can be used to help determine
              your likely interests. For your choices about interest-based ads
              from Portal, please see the “Cookies and Internet Advertising”
              section (below).
            </p>
          </div>
          <div className="mb-3 ">
            <h4 className="mt-4 ">Matched Identifier Communications.</h4>
            <p>
              Some third party sites and apps allow us to reach our users with
              online promotions about our titles and services by sending a
              privacy protective identifier to the third party. A privacy
              protective identifier means we convert the original information
              (such as an email address or phone number) into a value to keep
              the original information from being revealed. The third party
              compares the privacy protective identifier to identifiers in its
              database and there will be a match only if you have used the same
              identifier (such as an email address) with Portal and the third
              party. If there is a match, Portal can then choose whether or not to
              send a given promotional communication to you on that third party
              site or app, and can optimize and better measure the effectiveness
              of online advertising. You can opt out in the “Marketing
              Communications” section of the “Account” section of our website.
            </p>
          </div>
          <div className="mb-3 ">
            <h4 className="mt-4 ">Your Information and Rights</h4>
            <p>
              You can request access to your personal information, or correct or
              update out-of-date or inaccurate personal information we hold
              about you. You may also request that we delete personal
              information that we hold about you.
            </p>
            <p>
              When you visit the “Account” portion of our website, you have the
              ability to access and update a broad range of information about
              your account, including your contact information, your Portal
              payment information, and various related information about your
              account (such as the content you have viewed and rated). You must
              be signed in to access the “Account” section.
            </p>
          </div>

          <div className="mb-3 ">
            <h4 className="mt-4 ">Security</h4>
            <p>
              We use reasonable administrative, logical, physical and managerial
              measures to safeguard your personal information against loss,
              theft and unauthorized access, use and modification. These
              measures are designed to provide a level of security appropriate
              to the risks of processing your personal information.
              Unfortunately, no measures can be guaranteed to provide 100%
              security. Accordingly, we cannot guarantee the security of your
              personal information.
            </p>
          </div>

          <div className="mb-3 ">
            <h4 className="mt-4 ">
              Other Websites, Platforms and Applications
            </h4>
            <p>
              The Portal service may be provided through and/or utilize features
              (such as voice controls) operated by third party platforms, or
              contain links to sites operated by third parties whose policies
              regarding the handling of information may differ from ours. For
              example, you may be able to access the Portal service through
              platforms such as gaming systems, smart TVs, mobile devices, set
              top boxes and a number of other Internet connected devices. These
              websites and platforms have separate and independent privacy or
              data policies, privacy statements, notices and terms of use, which
              we recommend you read carefully. In addition, you may encounter
              third party applications that interact with the Portal service.
            </p>
          </div>

          <div className="mb-3 ">
            <h4 className="mt-4 ">Children</h4>
            <p>
              You must be at least 18 years of age or older to subscribe to the
              Portal service. Minors may only use the service with the
              involvement, supervision, and approval of a parent or legal
              guardian.
            </p>
          </div>

          <div className="mb-3 ">
            <h4 className="mt-4 ">Changes to This Privacy Statement</h4>
            <p>
              We will update this Privacy Statement from time to time in
              response to changing legal, regulatory or operational
              requirements. We will provide notice of any such changes
              (including when they will take effect) in accordance with law.
              Your continued use of the Portal service after any such updates take
              effect will constitute acknowledgement and (as applicable)
              acceptance of those changes. If you do not wish to acknowledge or
              accept any updates to this Privacy Statement, you may cancel your
              use of the Portal service. To see when this Privacy Statement was
              last updated, please see the “Last Updated” section below.
            </p>
          </div>
          <p>
            In connection with our use of these technologies, some of the
            websites and apps where we advertise, as well as advertising
            technology companies that we use to purchase, deliver, optimize,
            and/or measure our advertisements (collectively “Advertising
            Partners”), may receive limited information from us as part of our
            campaign targeting, measurement, and optimization (e.g., steps
            completed in sign-up and site visit or app open/install
            information).
          </p>

          <p>
            Common uses of this type of information are to judge the
            effectiveness of and optimize ad campaigns, by allowing Advertising
            Partners to see when someone who saw an ad later signed up for our
            service. Another common use is to make sure we do not serve ads
            trying to get individuals to sign up for the Portal service if they
            are already a Portal user.
          </p>

          <p>
            Portal uses contractual and technical measures designed to prevent
            Advertising Partners from accessing information regarding specific
            title selections you make, URLs you land on, or shows you have
            watched on our service. We do not share information about title
            selections or your shows you have watched on our service.
          </p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacypolicy);
