import { useEffect, useState } from "react";
import "./style.css";
import moment from "moment";
import { Link } from "react-router-dom";
import AppConfig from "../../config/app.config";

const Countdown = ({ time: targetDate, callback, orderId, price, employeeData }) => {
  const [time, setTime] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const getTime = () => {
      // const now = new Date(
      //   new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
      // )
      const now = new Date();
      const endDate = moment(targetDate, "MM/DD/YYYY hh:mm:ss").toDate();

      const diff = endDate - now;

      if (diff <= 0) {
        callback();
        return clearInterval(interval);
      }

      const hours = Math.floor(diff / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((diff % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");
      setTime({ hours, minutes, seconds });
    };

    const interval = setInterval(getTime, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  return (
    <div className="countdown-container">
      <h3 className="countdown-info">VIP SHOWTIME</h3>
      <div className="countdown-main">
        <div className="countdown-number">
          <p>{time.hours}</p>
          <p>hours</p>
        </div>
        <p className="countdown-column">:</p>
        <div className="countdown-number">
          <p>{time.minutes}</p>
          <p>minutes</p>
        </div>
        <p className="countdown-column">:</p>
        <div className="countdown-number">
          <p>{time.seconds}</p>
          <p>seconds</p>
        </div>
      </div>

      <div className="countdown-order">
        {/* <p className="countdown-info" style={{ marginBottom: "0px", fontSize:"42px" }}>
          Your Ticket is Confirmed!
        </p> */}
        {employeeData != null ? (
          <p
            className="countdown-info"
            style={{ marginBottom: "0px", fontSize: "42px" }}
          >
            {AppConfig.API.EVENT_ID === 2980039
              ? " Your Registration is Confirmed! "
              : " Stream Will Start Soon! "}
          </p>
        ) : (
          <>
            <Link
              to="/authentication/my-account"
              style={{
                width: "min-content",
                display: "block",
                marginInline: "auto",
              }}
            >
              <button className="countdown-btn" style={{ cursor: "pointer" }}>
                Regiter Now
              </button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Countdown;
