import axios from 'axios';
//import * as AxiosLogger from 'axios-logger';
import AppConfig from '../../config/app.config';

const http = axios.create({
    baseURL: AppConfig.API.ENDPOINT
});
//http.interceptors.request.use(AxiosLogger.requestLogger);
//http.interceptors.response.use(AxiosLogger.responseLogger);

export {
    http
}