import SignInActionTypes from './signIn.types';
// Set the reducers initial state.
const INITIAL_STATE = {
    loading: false,
    loginSuccess: null,
    errorMessage: null,
}

// Define scenarios for reducers.
const signInReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SignInActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccess: action.payload.loginSuccess,
                loading: action.payload.loading
            };
        case SignInActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loginSuccess: action.payload.loginSuccess,
                loading: action.payload.loading,
                errorMessage: action.payload.err
            };
        default:
            return state
    }
}

export default signInReducer;