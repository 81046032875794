import React from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import { bindActionCreators } from "redux"
import { selectemployeeData } from "../../store/home/home.selectors"
import Loader from "../../components/loader/loader"
import StripePayment from "../../components/stripe-payment/stripe-payment"
import AppConfig from "../../config/app.config"

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
})

export class SponsorPaymentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 0,
      employeeData: null,
      checkingUpdate: false,

      orderId: props.orderId,
      clientSecret: props.clientSecret,
      clientPublicKey: props.clientPublicKey,
      orderTotalAmount: props.orderTotalAmount,
      product: props.product,
      productObject: props.productObject,
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    var employeeData = this.props.employeeData
    this.setState({
      employeeData: employeeData,
    })
  }

  async componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <>
        {this.state.isLoading === 1 && <Loader />}
        <Modal
          show={this.props.show}
          // onHide={() => {
          //   this.props.onHide();
          // }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="add-cart-modal1"
        >
          <div id="addtocart-modal">
            <div className="sponsor-paymentdetails">
              <h5>Payment Details</h5>
              <div className="portal-modalclose">
                {/* <h4 className="add-new" onClick={() => this.props.onHide()}>
                  Close
                </h4> */}
                <img
                  src={require("../../assets/images/close x.png")}
                  onClick={() => this.props.onHide()}
                  alt="modal-closeicon"
                ></img>
              </div>
            </div>
            <div className="product-amount">
              <p>
                Product : <span>{this.state.productObject.productName}</span>
              </p>
              <p>
                Total Amount : <span>$ {this.state.orderTotalAmount}</span>
              </p>
            </div>
            {AppConfig.API.PAYMENT_MODE === 2 ? (
              {
                /* <CreditCardInfo
                setCardInfo={this.setCardInfo}
                cardNumber={this.state.cardNumber}
                cardHolderName={this.state.cardHolderName}
                cardExpiration={this.state.cardExpiration}
                cardCVC={this.state.cardCVC}
                checkingUpdate={this.state.checkingUpdate}
              /> */
              }
            ) : AppConfig.API.PAYMENT_MODE === 1 &&
              this.state.clientSecret != null &&
              this.state.clientSecret !== "" &&
              this.state.clientPublicKey != null &&
              this.state.clientPublicKey !== "" ? (
              <>
                <StripePayment
                  clientSecret={this.state.clientSecret}
                  clientPublicKey={this.state.clientPublicKey}
                  orderId={this.state.orderId}
                  returnUrl={
                    `${window.location.origin}/stripe-sponsor-payment-confirmation/` +
                    this.state.orderId
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>
        </Modal>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorPaymentModal)
