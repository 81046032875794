import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion, Card, Form } from "react-bootstrap";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/loader/loader";
import { selectemployeeData } from "../../store/home/home.selectors";
import SponsorPaymentModal from "../../components/model/sponsor-payment-modal";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import "swiper/swiper-bundle.css";
import { gsap } from "gsap";
import ContextAwareAccordionToggle from "../../components/context_aware_accordion_toggle";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
});

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

export class SponsorshipOpportunities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeData: null,
      products: null,
      price: "",

      productId: null,
      brandName: "",
      description: "",
      logo: null,
      logoSource: null,
      brandUrl: "",

      orderId: null,
      clientSecret: null,
      clientPublicKey: null,
      orderTotalAmount: null,
      product: null,

      isPaymentModalVisible: false,

      swiper: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId != null) {
      await this.getProducts();
      this.setState({ isLoading: 0 });
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: "/sponsorship",
      });
      this.props.history.push("/authentication/my-account");
    }
  }

  getProducts = async () => {
    try {
      const res = await UserService.getSponsorProducts(AppConfig.API.APP_ID);
      if (res.error != null) {
        if (res.error?.response?.data?.message != null) {
          NotificationManager.error(res.error.response.data.message, "", 3000);
        } else if (res.error.message != null) {
          NotificationManager.error(
            "Could not get Sponsor Products.",
            "",
            3000
          );
        }
      } else {
        if (res["status"]) {
          const data = res["data"];
          if (data === null) {
            NotificationManager.error(
              "Could not get Sponsor Products.",
              "",
              3000
            );
          } else {
            this.setState({ products: res["data"] });
            // this.setState({
            //   isPaymentModalVisible: true,
            //   clientSecret:
            //     "pi_3PqAA5Ge0Sbpt8DV0VxMituF_secret_5Xs9AfGRiNbD3iyspQwGZp0Th",
            //   clientPublicKey:
            //     "pk_test_51PnyHZGe0Sbpt8DVkId346MCWYy38a5Cwqg9jNGv9gFQMWOgGKOIKVIzXahxiE11nOXDmWum0pz2pssuVvUBu3pL00wiMX86pA",
            //   orderId: "2229640",
            //   orderTotalAmount: 2500,
            //   product: res["data"][0],
            //   productId:1214124
            // });
          }
        } else {
          NotificationManager.error(
            "Could not get Sponsor Products.",
            "",
            3000
          );
        }
      }
    } catch (err) {
      NotificationManager.error("Could not get Sponsor Products.", "", 3000);
    }
  };

  formatPhone = (value) => {
    const phoneNumber = value.replace(/\D/g, "");
    let formatted = "";
    if (phoneNumber.length > 3) {
      formatted += phoneNumber.substr(0, 3) + "-";
      if (phoneNumber.length > 6) {
        formatted += phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
      } else {
        formatted += phoneNumber.substr(3);
      }
    } else {
      formatted = phoneNumber;
    }
    return formatted.slice(0, 13);
  };

  handleBrandName = (event) => {
    this.setState({ brandName: event.target.value });
  };
  handleLogo = (event) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ logoSource: e.target.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    this.setState({ logo: event.target.files[0] });
  };
  handleBrandUrl = (event) => {
    this.setState({ brandUrl: event.target.value });
  };
  handleDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  isUrlValid = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };

  buySponsorship = async () => {
    if (this.state.productId == null) {
      NotificationManager.warning("Please Select Product.", "", 2000);
    } else if (this.state.brandName.trim() === "") {
      NotificationManager.warning("Please Enter Brand Name.", "", 2000);
    } else if (this.state.logo == null || this.state.logoSource == null) {
      NotificationManager.warning("Please Select Logo Image.", "", 2000);
    } else if (this.state.brandUrl.trim() === "") {
      NotificationManager.warning("Please Enter Url.", "", 2000);
    } else if (!this.isUrlValid(this.state.brandUrl)) {
      NotificationManager.warning("Please Enter valid Url.", "", 2000);
    } else if (this.state.description.trim() === "") {
      NotificationManager.warning("Please Enter Description.", "", 2000);
    } else {
      if (AppConfig.API.PAYMENT_MODE === 1) {
        const regex = /data:.*base64,/;
        try {
          const product = this.state.products.find(
            (product) => product.productId === this.state.productId
          );
          if (this.props.employeeData?.employeeId != null && product != null) {
            this.setState({ isLoading: 1 });
            const payload = {
              subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
              verticalId: AppConfig.API.VERTICAL_ID,
              appId: AppConfig.API.APP_ID,
              loginEmployeeId: this.props.employeeData?.employeeId,
              logo: this.state.logoSource.replace(regex, ""),
              url: this.state.brandUrl,
              description: this.state.description,
              productId: this.state.productId,
              price: product.productPrice,
              brandName: this.state.brandName,
              paymentMode: AppConfig.API.PAYMENT_MODE,
            };
            const res = await UserService.buySponsorship(payload);
            if (res.error != null) {
              NotificationManager.warning("Somthing went wrong!.", "", 2000);
              this.setState({ isLoading: 0 });
            } else {
              if (res["status"]) {
                var clientPublicKey =
                  res["data"]?.paymentGatewayInfo?.clientPublicKey;
                this.setState({
                  isPaymentModalVisible: true,
                  orderId: res["data"].orderId,
                  clientSecret: res["data"]?.paymentGatewayInfo?.clientSecret,
                  clientPublicKey: clientPublicKey,
                  orderTotalAmount:
                    res["data"].paymentGatewayInfo.orderTotalAmount,
                  product: res["data"].paymentGatewayInfo.product,
                  isLoading: 0,
                });
              } else {
                NotificationManager.warning(res["message"], "", 2000);
                this.setState({ isLoading: 0 });
              }
            }
          } else {
            this.props.history.push("/authentication/my-account");
          }
        } catch (err) {
          NotificationManager.error(err, "", 2000);
          this.setState({ isLoading: 0 });
        }
      } else if (AppConfig.API.PAYMENT_MODE === 2) {
        this.setState({
          isPaymentModalVisible: true,
        });
      } else {
        NotificationManager.warning(
          "Please Define Your Payment Mode",
          "",
          2000
        );
      }
    }
  };

  animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  render() {
    return (
      <div className="portal-conatct">
        {this.state.isLoading === 1 ? <Loader showLogo /> : ""}
        {this.state.products != null && (
          <div className="app-conatctus">
            <p>Sponsorship Opportunities</p>
            <Form.Group className="product-label">
              <Accordion id="tickets-details" className="sponsorProducts">
                {this.state.products
                  .sort((a, b) => a.productPrice - b.productPrice)
                  .map((product, index) => {
                    return (
                      <Card key={index}>
                        <Card.Header>
                          <ContextAwareAccordionToggle
                            // as={Button}
                            variant="link"
                            eventKey={`${index}`}
                            style={{ display: "flex" }}
                          >
                            <div className="tickets-toggle">
                              {/* <Collapsearrow /> */}
                              <img
                                src={require("../../assets/images/Portal/collapse-arrow.png")}
                                className="side-arrow-down"
                                alt="Side Arrow"
                              ></img>
                              <p>{`${product.productName} - $${product.productPrice}`}</p>
                              <h4>
                                Remaining Sponsorships:
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textAlign: "right",
                                  }}
                                >{` ${product.remainingQty} / ${product.totalQty}`}</span>
                              </h4>
                            </div>
                          </ContextAwareAccordionToggle>
                          <Form.Check
                            key={product.productId}
                            className="sponsorProduct-radio"
                            name="Products"
                            type="radio"
                            disabled={product.remainingQty === 0}
                            value={product.productId}
                            onChange={(e) => {
                              this.setState({
                                productId: parseInt(e.target.value),
                              });
                            }}
                          />
                        </Card.Header>
                        {product.productDescription != null && (
                          <Accordion.Collapse eventKey={`${index}`}>
                            <Card.Body>
                              <div className="tickets-description">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: product.productDescription,
                                  }}
                                />
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        )}
                      </Card>
                    );
                  })}
              </Accordion>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                className="mb-0 conatctname"
                placeholder="Brand Name"
                value={this.state.brandName}
                onChange={this.handleBrandName}
                autoComplete="off"
                required
              />
            </Form.Group>
            <div className="upload-logo-image">
              <Form.Group controlId="formFile">
                {this.state.logoSource != null && (
                  <img src={this.state.logoSource} alt="" />
                )}
                <div className="set-brandlogo-txt">
                  <label htmlFor="files" className="btn">
                    Choose Brand logo file
                  </label>
                  <p>
                    {this.state.logo != null
                      ? this.state.logo.name
                      : "No file Chosen"}
                  </p>
                </div>
                <input
                  className="input-field"
                  id="files"
                  type="file"
                  accept="image/*"
                  onChange={this.handleLogo}
                  required
                />
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Control
                type="text"
                className="mb-0 conatctname"
                placeholder="Url"
                value={this.state.brandUrl}
                onChange={this.handleBrandUrl}
                autoComplete="off"
                required
              />
            </Form.Group>
            <Form.Group>
              <textarea
                className="mb-0 message-inbox"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleDescription}
                autoComplete="off"
                required
              ></textarea>
            </Form.Group>

            <div className="send-btn">
              <button
                type="submit"
                className="contactdata-send"
                onClick={this.buySponsorship}
              >
                Checkout
              </button>
            </div>
          </div>
        )}
        {this.state.orderId != null ? (
          <SponsorPaymentModal
            show={this.state.isPaymentModalVisible}
            onHide={() => {
              this.setState({
                orderId: null,
                clientSecret: null,
                orderTotalAmount: null,
                product: null,

                isPaymentModalVisible: false,
              });
            }}
            orderId={this.state.orderId}
            clientSecret={this.state.clientSecret}
            clientPublicKey={this.state.clientPublicKey}
            orderTotalAmount={this.state.orderTotalAmount}
            product={this.state.product}
            productObject={this.state.products.find(
              (product) => product.productId === this.state.productId
            )}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorshipOpportunities);
