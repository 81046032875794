import { Component } from "react";

export default class ReferralPage extends Component {
  componentDidMount() {
    const code = this.props.match?.params?.code;
    if (code != null) {
      sessionStorage.setItem("referralCode", code);
    }
    this.props.history.push("/");
  }

  render() {
    return <div></div>;
  }
}
