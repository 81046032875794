import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import { selectemployeeData } from "../../store/home/home.selectors";
import { withRouter } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./ReplayPlayer.css";

const mapStateToProps = (state) => {
  return { employeeData: selectemployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
    },
    dispatch
  ),
});

class ReplayPlayer extends React.Component {
  render() {
    const { videoTitle, videoSubtitle, videoUrl, isHeaderVisible, onClose } =
      this.props;

    return (
      <section className="height-100-vh video-wrapper" id="video-section">
        {isHeaderVisible && (
          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "#00000099",
              position: "fixed",
              top: 0,
              zIndex: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0 50px",
            }}
            className="video-mobile"
          >
            <div className="video-mobile-back">
              <ArrowBackIcon
                className="video-back"
                style={{ height: "35px", width: "35px", cursor: "pointer" }}
                onClick={onClose}
              />
              <div className="video-movies">
                <p className="video-series-title">{videoTitle}</p>
                {videoSubtitle && (
                  <p className="video-series-episode">{videoSubtitle}</p>
                )}
              </div>
            </div>
          </div>
        )}
        <ReactPlayer
          //   url={
          //     "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
          //   }
          url={videoUrl}
          playing={true}
          controls
          width="100%"
          height="100%"
          playbackRate={1}
        />
      </section>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReplayPlayer)
);
