import { takeLeading, put, all, call } from "redux-saga/effects";
import SignInActionTypes from "./signIn.types";
import { userLoginSuccess, userLoginFailure } from "./signIn.actions";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { log } from "../../helpers/core.helper";
import { setEmployeeData } from "../home/home.actions";

/** performs user login */
export function* loginUser({ payload }) {
  try {
    const signInData = {
      username: payload.username,
      password: payload.password,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL,
    };
    const res = yield UserService.signIn(signInData);
    if (res.error != null) {
      yield put(
        userLoginFailure({
          loginSuccess: false,
          loading: false,
          err: res.error?.response?.data?.message,
        })
      );
    } else {
      if (res["status"]) {
        const employeeList =
          res["data"] != null && res["data"].length > 0
            ? res["data"][0].employees
            : null;
        let isLogin = false;
        if (employeeList != null) {
          for (let i = 0; i < employeeList.length; i++) {
            const row = employeeList[i];
            if (row.sphereTypeId === 2) {
              localStorage.setItem("employeeId", row.employeeId);
              localStorage.setItem("employeeEmail", row.email);
              localStorage.setItem("employeeData", JSON.stringify(row));
              localStorage.setItem("employeeName", row.name);
              yield put(setEmployeeData({ employeeData: row }));
              isLogin = true;
            }
          }
        }

        if (isLogin) {
          yield put(userLoginSuccess({ loginSuccess: true, loading: true }));
        } else {
          yield put(
            userLoginFailure({
              loginSuccess: false,
              loading: false,
              err: "Invalid Credentials.",
            })
          );
        }
      } else {
        yield put(
          userLoginFailure({
            loginSuccess: false,
            loading: false,
            err: res["message"],
          })
        );
      }
    }
  } catch (err) {
    log("signIn.sagas", "loginUser", err);
    yield put(
      userLoginFailure({ loginSuccess: false, loading: false, err: err })
    );
  }
}

/** Saga for user login */
export function* userLogin() {
  yield takeLeading(SignInActionTypes.LOGIN_START, loginUser);
}

/**
 * Initialise all local sagas
 */
export function* signInSagas() {
  yield all([call(userLogin)]);
}
