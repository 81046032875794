import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectemployeeData } from "../../store/home/home.selectors";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import Loader from "../../components/loader/loader";
import { ReactComponent as Failure } from "../../assets/images/Failure.svg";
import { ReactComponent as Ongoing } from "../../assets/images/Ongoing.svg";
import { ReactComponent as Somthingwrong } from "../../assets/images/somthingwrong.svg";
import { ReactComponent as CloseBlackIcon } from "../../assets/images/close_icon_black.svg";
import "./confirmation.css";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeEmail: null,
      orderId: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var employeeData = this.props.employeeData;
    if (employeeData?.email != null) {
      this.setState({
        employeeEmail: employeeData.email,
      });
      this.validatePayment();
    } else {
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    if (this.validateTimeOut != null) {
      clearTimeout(this.validateTimeOut);
    }
  }

  validatePayment = () => {
    this.setState({ isLoading: 1 });
    const orderId = parseInt(window.location.pathname.split("/")[2]);
    this.setState({ orderId: orderId });
    const payload = {
      orderId,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
    };
    UserService.getSponsorPaymentStatus(payload)
      .then(async (res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0, paymentStatus: 3 });
        } else {
          if (res["status"]) {
            if (res["data"] != null && res["data"].orderStatus !== null) {
              const orderStatus = res["data"].orderStatus;
              const orderError = res["data"].orderError;
              var paymentStatus = 3;
              if (orderStatus === "PAYMENT_COMPLETE") {
                paymentStatus = 0;
                //NotificationManager.success("Transaction Success", "", 2000);
              }
              if (orderStatus === "PAYMENT_FAIL") {
                paymentStatus = 1;
                //NotificationManager.error("Transaction Failed", "", 2000);
              }
              if (orderStatus === "PAYMENT_PROCESS") {
                paymentStatus = 2;
                //NotificationManager.success("Transaction Ongoing", "", 2000);
              }
              this.setState({
                isLoading: 0,
                paymentStatus,
                paymentError: orderError,
              });
            }
          } else {
            //NotificationManager.error(res["message"], "", 2000);
            this.setState({ isLoading: 0, paymentStatus: 3 });
          }
        }
      })
      .catch((err) => {
        //NotificationManager.error(err, "", 2000);
        this.setState({ isLoading: 0, paymentStatus: 3 });
      });
  };

  render() {
    return this.state.isLoading === 1 ? (
      <Loader showLogo />
    ) : (
      <div
        className="todays-portal-home"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          className="portal-confirmation"
          style={{
            display: "flex",
            // flexDirection: "column",
            flex: 1,
            backgroundColor: "#000",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div id="order-confiramtion-modal" className="stripe-sponsor-modal">
            <div className="modal-content">
              <CloseBlackIcon
                className="close-icon icon-btn"
                onClick={() => this.props.history.push("/")}
              />
              <div className="order-title">
                <p className="order-title-text">Order Confirmation </p>
              </div>
              <p className="stream-text">Sponsor</p>
              {this.state.paymentStatus === 0 ? (
                <>
                  <p className="link-text" id="link-text">
                    {" "}
                    Order ID: {this.state.orderId}
                  </p>
                  <p className="link-text" id="link-text">
                    Your sponsorship payment has been sucessfully completed.
                  </p>
                </>
              ) : this.state.paymentStatus === 1 ? (
                <>
                  <Failure className="payment-img" />
                  <p className="link-text">
                    Failure <br></br>
                    Oops! Your transaction was unsuccessful. Please try again or
                    contact our customer support for assistance.
                  </p>
                </>
              ) : this.state.paymentStatus === 2 ? (
                <>
                  <Ongoing className="payment-img" />
                  <p className="link-text">
                    Ongoing <br></br>
                    Your transaction is in progress. Please wait while we
                    process your payment. Thank you for your patience.
                  </p>
                </>
              ) : (
                <>
                  <Somthingwrong className="payment-img" />
                  <p className="link-text">
                    Oops! <br></br>
                    Something went wrong couldn't get your payment status.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
