import { Component } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";
import { ReactComponent as HandIcon } from "../../assets/images/Portal/Hand.svg";

class SignupSuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.props.onHide();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="add-cart-modal1"
      >
        <div id="addtocart-modal">
          {/* <Modal.Header closeButton></Modal.Header> */}
          <div className="portal-modalclose">
            {/* <h4 className="add-new" onClick={() => this.props.onHide()}>
              Close
            </h4> */}
            <Closeicon
              className="close-icon"
              onClick={() => this.props.onHide()}
            />
          </div>
          <div className="addtocart-information">
            {/* <img
              src={require("../../assets/images/Congrats.png")}
              alt="Congrats"
              className="hand-img"
            ></img> */}
            <HandIcon className="hand-img" />
            <p>
              Welcome! <br></br>
              Your account is created
            </p>
            <div className="set-btns-tickets">
              <button className="Close" onClick={() => this.props.onHide()}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default SignupSuccessModal;
