import { http } from "../../services/app/app.service";

const UserService = {
  /**
   * User Sign in api call old api//m1972463
   * @param {object} signInData - the signin payload
   */
  signIn: (signInData) => {
    return http
      .post("/commonrestapi/m1342055", signInData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * User Sign Up api call
   * @param {object} signUpData - the signup payload
   */
  signUp: (signUpData) => {
    return http
      .post("/todaysPortal/m2782621", signUpData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get barnds list api call
   * @param {object} payload - the post data payload
   */
  getBrandsList: (payload) => {
    return http
      .post("/zazafest/m2097177", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * send reset password api call
   * @param {object} payload - the post data payload
   */
  sendResetPasswordLink: (payload) => {
    return http
      .post("/commonrestapi/m1319044", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * validate reset key api call
   * @param {object} payload - the post data payload
   */
  validateResetKey: (payload) => {
    return http
      .post("/commonrestapi/m1319043", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * save reset password api call
   * @param {object} payload - the post data payload
   */
  saveResetPassword: (payload) => {
    return http
      .post("/commonrestapi/m1319051", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get user profile in call api
   * @param {Long} employeeId - the employeeId
   */
  getUserProfile: (employeeId) => {
    return http
      .get(`/commonrestapi/m1064731/${employeeId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * save user profile data api call
   * @param {object} payload - the post data payload
   * @param {Long} employeeId - the employeeId
   */
  updateUserProfile: (payload, employeeId) => {
    return http
      .put(`/commonrestapi/m1065385/${employeeId}`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * update password api call
   * @param {object} payload - the post data payload
   */
  updatePassword: (payload) => {
    return http
      .post("/commonrestapi/m1303853", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get Channel Category api call
   * @param {object} payload - the post data payload
   */
  getChannelCategoryList: (payload) => {
    return http
      .post("/zazafest/m2097171", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get payment status api call
   * @param {object} payload - the post data payload
   */
  getPaymentStatus: (payload) => {
    return http
      .post("/zazafest/m2098902", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get payment status api call
   * @param {object} payload - the post data payload
   */
  getSponsorPaymentStatus: (payload) => {
    return http
      .post("/zazafest/m2136564", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * update Transaction Address api call
   * @param {object} payload - the post data payload
   */
  updateTransactionAddress: (payload) => {
    return http
      .post("/veuitNetwork/m2088805", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get All events from master eventId
   * @param {number} eventId - the post data payload
   */
  getEventListFromMasterEventId: (eventId) => {
    return http
      .get(`/zazafest/m2097172/${eventId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get All events from master eventId
   * @param {string} zipCode - the post data payload
   */
  getTaxByZipcode: (zipCode) => {
    return http
      .get(`/zazafest/m2097403/${zipCode}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Buy Event Ticket m2097404
   * @param {object} payload - the post data payload  striperventticketorder
   */
  buyEventTicket: (payload) => {
    return http
      .post("/zazafest/m2097404", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get All events from master eventId
   * @param {string} contractId - the post data payload
   * @param {string} eventId - the post data payload
   */
  getTicketDetail: (contractId, eventId) => {
    return http
      .get(`/zazafest/m2097725/${contractId}/${eventId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get All State by Country
   * @param {string} countryd - the post data payload
   */
  getStateList: (countryd) => {
    return http
      .get(`/commonrestapi/m1346624/${countryd}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get All Tickets
   * @param {object} payload - the post data payload
   */
  getAllTickets: (payload) => {
    return http
      .post("/zazafest/m2106314", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   *
   * @param {object} payload - to get event data
   */
  getEventData: (payload) => {
    return http
      .post("/todaysPortal/m2783023", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get contact us Subjects
   * @param {object} payload - the post data payload
   */
  getContactSubjects: (payload) => {
    return http
      .post("/commonrestapi/m1391103", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * save contact us
   * @param {object} payload - the post data payload
   */
  saveContactUs: (payload) => {
    return http
      .post("/commonrestapi/m1357069", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get faq list
   * @param {object} payload - the post data payload
   */
  getFaqs: (payload) => {
    return http
      .post("/commonrestapi/m1388407", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get apple pass
   * @param {string} ticketCode - ticketCode
   * @param {number} eventId - eventId
   */
  getApplePass: (ticketCode, eventId) => {
    return http
      .get(`/zazafest/m2097725/${ticketCode}/${eventId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get address list
   * @param {string} employeeId - employeeId
   */
  getAddressList: (employeeId) => {
    return http
      .get(`/zazafest/m2114943/${employeeId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get sponsor projducts list
   */
  getSponsorProducts: (appId) => {
    return http
      .get(`/zazafest/m2127619/${appId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * buy sponsorship
   * @param {object} payload - the post data payload
   */
  buySponsorship: (payload) => {
    return http
      .post("/zazafest/m2127657", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get employee profile data
   * @param {number} emoployeeId - the post data payload
   */
  getEmployeeProfileDetail: (employeeId) => {
    return http
      .get(`commonrestapi/m1064731/${employeeId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * save employee profile data
   * @param {number} emoployeeId - the post data payload
   * @param {object} payload - the post data payload
   */
  saveEmployeeProfileDetail: (employeeId, payload) => {
    return http
      .put(`commonrestapi/m1065385/${employeeId}`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get total tickets sold
   * @param {object} payload - the post data payload
   */
  getTotalTicketsSold: (payload) => {
    return http
      .post("/zazafest/m2135903", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * log total tickets sold
   * @param {object} payload - the post data payload
   */
  logTotalTicketsSold: (payload) => {
    return http
      .post("/zazafest/m2136118", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get sponsor images
   * @param {object} payload - the post data payload
   */
  getSponsorImages: (payload) => {
    return http
      .post("/deck/m1357310", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  logDocumentTime: (payload) => {
    return http
      .post(`commonrestapi/m1344862`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  setPassword: (payload) => {
    return http
      .post("/zazafest/m2124736", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  merchantRegistration: (payload) => {
    return http
      .post("/cannabispay/m1915077", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get Sponsor list
   * @param {object} payload - the post data payload
   */
  getSponsorsList: (payload) => {
    return http
      .post("/zazafest/m2182135", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * Send Ticket
   * @param {object} payload - the post data payload
   */
  sendTicket: (payload) => {
    return http
      .post("/zazafest/m2112358", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get tickets
   * @param {number} ticketTransferLogId - the post data payload
   */
  getTicketsByLogId: (ticketTransferLogId) => {
    return http
      .get(`zazafest/m2183182/${ticketTransferLogId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * update ticket status
   * @param {object} payload - the post data payload
   */
  updateTicketStatus: (payload) => {
    return http
      .post("/zazafest/m2182933", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * Get All Card List
   * @param {object} payload - the post data payload
   */
  getAllCardList: (payload) => {
    return http
      .post("/zazafest/m2187679", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * add card detail
   * @param {object} payload - the post data payload
   */
  addStripeCard: (payload) => {
    return http
      .post("/zazafest/m2187681", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * payment using card detail
   * @param {object} payload - the post data payload
   */
  makeStripePayment: (payload) => {
    return http
      .post("/zazafest/m2187684", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * delete stripe card
   * @param {object} payload - the post data payload
   * @param {Long} employeeId - the employeeId
   */
  deleteCard: (payload, employeeId) => {
    return http
      .put(`/zazafest/m2187979/${employeeId}`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * delete stripe card
   * @param {Long} appId - get terms and condition
   */
  getTerms: (appId) => {
    return http
      .get(`/todaysPortal/m2835038/${appId}`)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * set card is default card
   * @param {object} payload - the post data payload
   * @param {Long} employeeId - the employeeId
   */
  setDefaultCard: (payload, employeeId) => {
    return http
      .put(`/zazafest/m2187682/${employeeId}`, payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * affiliate user registration
   * @param {object} payload - the post data payload
   */
  affiliateUserReg: (payload) => {
    return http
      .post("planethunny/m1353207", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * get countyList
   * @param {object} payload - the post data payload
   */
  getCountryList: (payload) => {
    return http
      .post("commonadmin/m1364887", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * get payment status api call
   * @param {object} payload - the post data payload
   */
  getWatchlistEventData: (payload) => {
    return http
      .post("/todaysPortal/m2914034", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   * subscribeEmail
   * @param {object} payload - the post data payload
   */
  subscribeEmail: (payload) => {
    return http
      .post("/commonrestapi/m1305506", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   *Validate Promocode
   * @param {object} payload - the post data payload
   */
  validatePromoCode: (payload) => {
    return http
      .post("/zazafest/m2990928", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   *apply Promocode
   * @param {object} payload - the post data payload
   */
  applyPromoCode: (payload) => {
    return http
      .post("/zazafest/m2990954", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
};

export default UserService;
