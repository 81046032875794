import { Component } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Closeicon } from "../../assets/images/close_icon_black.svg";
import { ReactComponent as HandIcon } from "../../assets/images/Portal/Hand.svg";

class ContactUsSuccessMoal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal
        show={this.props.isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="contact-us-success-modal"
      >
        <div >
          <div className="portal-modalclose">
            <Closeicon
              className="close-icon"
              onClick={() => this.props.onHide()}
            />
          </div>
          <div className="order-confiramtion-modal">
            <div
              className="order-title"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              <p className="order-title-text" style={{ textAlign: "center" }}>
                Thanks for connecting!
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4rem",
                marginBottom: "1rem",
              }}
            >
              <button
                style={{
                  color: "#4A81CF",
                  fontFamily: "Avenir",
                  fontWeight: "700",
                  fontSize: "14.4px",
                  letterSpacing: "0.26",
                  backgroundColor: "#fff",
                  borderColor: "#4A81CF",
                  borderWidth: "thin",
                  borderRadius: "5px",
                  width: "88px",
                  height: "35px",
                }}
                onClick={() => this.props.onHide()}
              >
                CLOSE{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ContactUsSuccessMoal;
