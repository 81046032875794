import React from "react";
import { Link } from "react-router-dom";
import { selectemployeeData } from "../../store/home/home.selectors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setEmployeeData } from "../../store/home/home.actions";
import { ReactComponent as Iconwhite } from "../../assets/images/header-right.svg";
import { Drawer } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AppConfig from "../../config/app.config";
import "../../assets/css/dstyles.css";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setEmployeeData }, dispatch),
});

export class HeaderManu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeData: props.employeeData,
      isDrawerOpen: false,
      isDropdownOpen: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.employeeData !== prevProps.employeeData) {
      this.setState({
        employeeData: this.props.employeeData,
      });
    }
  }

  render() {
    return (
      <div className="app-header">
        <div className="header-myaccount">
          <div
            className="header-space"
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            {this.state.employeeData != null && (
              <>
                <img
                  src={
                    AppConfig.API.USER_PROFILE +
                    this.state.employeeData.employeeId
                  }
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                  }}
                  alt="img"
                />
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {this.state.employeeData.customerName}
                </p>
              </>
            )}
          </div>
          <div className="header-logodesign" style={{ marginRight: "7rem" }}>
            {AppConfig.API.APP_ID === 2836 ? (
              <Link to="/">
                <img
                  src={require("../../assets/images/CirightLive/ciright-live-logo.png")}
                  alt="ciright"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={require("../../assets/images/veuit/TV-4.png")}
                  alt="ciright"
                  height="150px"
                  width="150px"
                />
              </Link>
            )}
          </div>

          {/* <Dropdown
              show={this.state.isDropdownOpen}
              onToggle={() =>
                this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
              }
              className="portal-dropdown"
            >
              <Dropdown.Toggle id="dropdown-basic">
                <p>MY ACCOUNT</p>
              </Dropdown.Toggle>

              <Dropdown.Menu id="web-dropdown">
                {this.state.employeeData != null ? (
                  <>
                    <Link
                      to="/account-info"
                      onClick={() => this.setState({ isDropdownOpen: false })}
                    >
                      <div className="dropdown-item">Profile</div>
                    </Link>
                    <Link
                      to="/checkout-ticket"
                      onClick={() => this.setState({ isDropdownOpen: false })}
                    >
                      <div className="dropdown-item">Buy Live Stream</div>
                    </Link>
                    <div
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.clear();
                        this.props.setEmployeeData({ employeeData: null });
                        this.setState({ isSession: 0, isDropdownOpen: false });
                        window.location.reload(false);
                      }}
                    >
                      Sign out
                    </div>
                  </>
                ) : (
                  <>
                    <Link
                      to="/authentication/my-account"
                      onClick={() => this.setState({ isDropdownOpen: false })}
                    >
                      <div className="dropdown-item">Create Account</div>
                    </Link>
                    <Link
                      to="/authentication/my-account"
                      onClick={() => this.setState({ isDropdownOpen: false })}
                    >
                      <div className="dropdown-item">Sign in</div>
                    </Link>
                    <Link
                      to="/checkout-ticket"
                      onClick={() => this.setState({ isDropdownOpen: false })}
                    >
                      <div className="dropdown-item">Buy Live Stream</div>
                    </Link>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown> */}
          {this.state.employeeData != null ? (
            <div className="banner-signinbtn">
              {/* {AppConfig.API.EVENT_ID !== 2980039 && (
                <button
                  onClick={() => this.props.history.push("/watchlist")}
                  className="bannerWatch-btn"
                >
                  Watchlist
                </button>
              )} */}
              <button
                onClick={() => {
                  localStorage.clear();
                  this.props.setEmployeeData({ employeeData: null });
                  this.setState({ isSession: 0, isDropdownOpen: false });
                  window.location.reload(false);
                  this.props.history.push("/");
                }}
                className="bannersign-btn"
              >
                Sign Out
              </button>
            </div>
          ) : (
            <div className="banner-signinbtn">
              <Link to="/authentication/my-account">
                <button className="bannersign-btn">Sign in</button>
              </Link>
            </div>
          )}
        </div>

        <div className="header-toggle-drawer">
          <div className="header-toggle-icon">
            {this.state.employeeData != null ? (
              <img
                onClick={() => this.setState({ isDrawerOpen: true })}
                src={
                  AppConfig.API.USER_PROFILE +
                  this.state.employeeData.employeeId
                }
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                }}
                alt="img"
              />
            ) : (
              <Iconwhite
                onClick={() => this.setState({ isDrawerOpen: true })}
                id="menuIconwhite"
              />
            )}

            {AppConfig.API.APP_ID === 2836 ? (
              <Link to="/">
                <img
                  src={require("../../assets/images/CirightLive/ciright-live-logo.png")}
                  alt="ciright"
                  className="portals-logo"
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={require("../../assets/images/veuit/TV-4.png")}
                  alt="ciright"
                  height="150px"
                  width="150px"
                />
              </Link>
            )}
            <div className="width-logospace"></div>
          </div>
          <Drawer
            anchor="left"
            open={this.state.isDrawerOpen}
            onClose={() => this.setState({ isDrawerOpen: false })}
            id="set-mob-drawer"
          >
            <div className="header-lineup-text" id="togglemenu-mobile">
              <img
                src={require("../../assets/images/close x.png")}
                alt="Close"
                className="drawerclose-icon"
                onClick={() => this.setState({ isDrawerOpen: false })}
              ></img>
              {/* <Link
                to="/"
                onClick={() => this.setState({ isDrawerOpen: false })}
              > */}
              {/* </Link> */}
              <div className="space-header-manu">
                {this.state.employeeData != null ? (
                  <>
                    {/* <Link
                      to="/account-info"
                      onClick={() => this.setState({ isDrawerOpen: false })}
                    >
                      <p>Profile</p>
                    </Link> */}
                    {/* <Link
                      to="/checkout-ticket"
                      onClick={() => this.setState({ isDrawerOpen: false })}
                    >
                      <p>Buy Live Stream</p>
                    </Link> */}
                    <img
                      src={
                        AppConfig.API.USER_PROFILE +
                        this.state.employeeData.employeeId
                      }
                      style={{
                        height: "65px",
                        width: "65px",
                        borderRadius: "50%",
                      }}
                      alt="img"
                    />
                    <p style={{ marginBottom: "1rem" }}>
                      {this.state.employeeData.customerName}
                    </p>
                    {AppConfig.API.EVENT_ID !== 2980039 && (
                      <Link
                        to="/watchlist"
                        onClick={() => {
                          this.setState({
                            isSession: 0,
                            isDrawerOpen: false,
                          });
                        }}
                      >
                        <p style={{ cursor: "pointer", color: "#3DACFF" }}>
                          Watchlist
                        </p>
                      </Link>
                    )}
                    <Link to="/">
                      <p
                        style={{ cursor: "pointer", color: "#3DACFF" }}
                        onClick={() => {
                          localStorage.clear();
                          this.props.setEmployeeData({ employeeData: null });
                          this.setState({
                            isSession: 0,
                            isDrawerOpen: false,
                          });
                          this.props.history.push("/");
                        }}
                      >
                        Sign Out
                      </p>
                    </Link>
                  </>
                ) : (
                  <>
                    {/* <Link
                      to="/authentication/my-account"
                      onClick={() => this.setState({ isDrawerOpen: false })}
                    >
                      <p> Create Account</p>
                    </Link> */}

                    <Link
                      to="/authentication/my-account"
                      onClick={() => this.setState({ isDrawerOpen: false })}
                    >
                      <p>Sign In</p>
                    </Link>
                    {/* <Link
                      to="/checkout-ticket"
                      onClick={() => this.setState({ isDrawerOpen: false })}
                    >
                      <p>Buy Live Stream</p>
                    </Link> */}
                  </>
                )}

                {/* <hr className="line"></hr>
                <Link
                  to="#"
                  //onClick={() => this.setState({ isDrawerOpen: false })}
                >
                  <h4>My Watchlist</h4>
                </Link>

                <Link
                  target="_blank"
                  to={{
                    pathname:
                      "https://influencer.ciright.live/referral-link/1294120/srs9nhx50t",
                  }}
                >
                  <h4>Affiliates</h4>
                </Link>
                <Link
                  to="/sponsorship"
                  onClick={() => this.setState({ isDrawerOpen: false })}
                >
                  <h4>Sponsorship</h4>
                </Link>
                <Link
                  to="#"
                  //onClick={() => this.setState({ isDrawerOpen: false })}
                >
                  <h4>contact us</h4>
                </Link>
                <Link
                  to="#"
                  // onClick={() => this.setState({ isDrawerOpen: false })}
                >
                  <h4>Terms & Conditions</h4>
                </Link> */}
                {/* <Link
                to="/terms-of-use"
                onClick={() => this.setState({ isDrawerOpen: false })}
              >
                <h4>terms of use</h4>
              </Link>
              <Link
                to="/privacy-policy"
                onClick={() => this.setState({ isDrawerOpen: false })}
              >
                <h4>privacy policy</h4>
              </Link> */}
              </div>
            </div>
          </Drawer>
          <div></div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderManu));
