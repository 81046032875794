import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

class Loader extends React.Component {
  render() {
    return (
      <>
        {this.props.showLogo && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.innerHeight,
              width: "100%",
              zIndex: 9999998,
            }}
          >
            {/* <APPLOGO className="portals-logo" /> */}
          </div>
        )}
        <Backdrop style={{ zIndex: 9999999, color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export default Loader;
