import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import UserService from "../../services/user/user.service";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";
import { NotificationManager } from "react-notifications";
import AppConfig from "../../config/app.config";
import Loader from "../../components/loader/loader";
import { setEventData } from "../../store/home/home.actions";
import { bindActionCreators } from "redux";
import { useDispatch, connect } from "react-redux";
import { selecteventData } from "../../store/home/home.selectors";


const mapStateToProps = (state) => {
  return {
    eventData: selecteventData(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setEventData
    },
    dispatch
  ),
});

const Affiliates = ({ isOpen, onHide, eventData }) => {
  const dispatch = useDispatch()
  //   const [isShowCardModel, setIsShowCardModel] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [stateMap, setStateMap] = useState([]);
  const [countryMap, setCountryMap] = useState([]);
  const userData = JSON.parse(localStorage.getItem("employeeData"));
  const [password, setPassword] = useState("");
  const [passwordProgress, setPasswordProgress] = useState(0);
  // const [eventData, setEventData] = useState(null);

  const getEventListFromMasterEventId = async () => {
    try {
      const employeeId = userData?.employeeId ?? -1;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const res = await UserService.getEventData({
        eventId: AppConfig.API.EVENT_ID,
        appId: AppConfig.API.APP_ID,
        employeeId: employeeId,
        timeZone: timeZone,
      });

      if (res.error != null) {
        // NotificationManager.warning("Something Went Wrong!!", "", 2000);
      } else if (res.status) {
        localStorage.setItem("eventData", JSON.stringify(res.data));
        dispatch(setEventData({ eventData: res.data }));
      }
    } catch (err) {
      console.error(err, "", 2000);
    }
  };

  const handlePassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };
  const removeExtraSpace = (value) => value.trim();
  const formatPhone = (value) => value.replace(/[^0-9]/g, "");

  const handleInputChange = (setter) => (e) => {
    const { value } = e.target;
    setter(value);
  };
  const getStateList = async () => {
    try {
      const res = await UserService.getStateList(countryId);
      if (res.error != null) {
        // console.log(res.error?.response?.data?.message);
      } else {
        if (res["status"]) {
          setStateMap(res["data"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getCountryList = async () => {
    try {
      const res = await UserService.getCountryList({
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
      });
      if (res.error != null) {
        // console.log(res.error?.response?.data?.message);
      } else {
        if (res["status"]) {
          setCountryMap(res["data"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const resetForm = () => {
    setFirstName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setStateId("");
    setCity("");
    setZipcode("");
    setPassword("CirightLive@123");
    setCountryId(-1);
  };
  useEffect(() => {
    getCountryList();
    if (localStorage.getItem("eventData") != null) {
      const eventData = JSON.parse(localStorage.getItem("eventData"));
      dispatch(setEventData({ eventData: eventData }))
    }
  }, []);

  useEffect(() => {
    // getCountryList()
    if (countryId !== -1) {
      getStateList();
    }
  }, [countryId]);

  useEffect(() => {
    const calculatePasswordStrength = (password) => {
      let strength = 0;
      if (password.length >= 8) strength += 25;
      if (/[A-Z]/.test(password)) strength += 25;
      if (/[a-z]/.test(password)) strength += 25;
      if (/\d/.test(password)) strength += 25;
      return strength;
    };

    setPasswordProgress(calculatePasswordStrength(password));
  }, [password]);

  const handleZipcodeChange = (e) => {
    const value = e.target.value;
    const numberRule = /^[0-9]*$/;
    if (numberRule.test(value)) {
      setZipcode(removeExtraSpace(value));
    }
  };

  const handlePhoneChange = (e) => {
    setPhone(formatPhone(e.target.value));
  };

  const handleSubmit = async () => {
    const fNumber = phone.split("-").join("");

    if (address.trim() === "") {
      NotificationManager.warning("Please Enter Address.", "", 2000);
    } else if (city.trim() === "") {
      NotificationManager.warning("Please Enter City.", "", 2000);
    } else if (stateId.trim() === "") {
      NotificationManager.warning("Please Select State.", "", 2000);
    } else if (zipcode.trim() === "") {
      NotificationManager.warning("Please Enter Zip Code.", "", 2000);
    } else {
      setLoading(true);
      const origin = AppConfig.API.APP_ID === 2838 ? "https://live.veuit.com" : "https://ciright.live";
      const payload = {
        name: userData.name,
        email: userData.email,
        address,
        city,
        zipcode,
        phone: userData.phone,
        password: "CirightLive@123",
        stateId,
        countryId: 1,
        subscriptionId: 8914081,
        verticalId: 2803,
        appId: 2837,
        affiliateId: 336011,
        manufacturerId: 1294120,
        linkType: 0,
        link: "srs9nhx50t",
        inviteCode: null,
        affiliateType: null,
        promoLink:
          origin +
          "/referral/" +
          AppConfig.API.APP_ID +
          userData.employeeId,
      };

      try {
        const res = await UserService.affiliateUserReg(payload);
        if (res.error != null) {
          NotificationManager.error(
            res.error?.response?.data?.message ?? "Could not Save Affiliate.",
            "",
            3000
          );
          setLoading(false);
        } else {
          if (res["status"]) {
            NotificationManager.success(
              "Affiliate Save Successfully.",
              "",
              3000
            );
            await getEventListFromMasterEventId();
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            setLoading(false);
          } else {
            await getEventListFromMasterEventId()
            NotificationManager.error("", res["message"], 3000);
            setLoading(false);
          }
        }
      } catch (err) {
        setLoading(false);
        NotificationManager.error("Could not Save Affiliate.", "", 3000);
      }
      resetForm();
      onHide();
    }
  };

  return (
    <div>
      <Modal
        show={isOpen}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="add-cart-modal"
        className="create-acoount-content"
      >
        {loading && <Loader />}
        <div
          className="portal-accountcreation-left"
          id="addtocart-modal-account"
        >
          <div className="portal-modalclose">
            {/* <h4
                  className="add-new"
                  onClick={() =>
                    this.setState({
                      isSignUpModalVisible: false,
                      email: "",
                      phone: "",
                      userName: "",
                      // lastName: "",
                      password: "",
                      passwordProgress: 0,
                      repeatedPassword: "",
                      isDisabled: "",
                    })
                  }
                >
                  close
                </h4> */}
            <Closeicon
              className="close-icon"
              onClick={() => {
                onHide();
                resetForm();
              }}
            />
          </div>
          <div className="create-acoount-content" id="Cust-Info">
            {eventData != null && eventData?.link != null ? (
              <>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}>

                  <h4 style={{color :"#fff"}}>Earn 10% on Referel</h4>
                  <div
                    className="createacoount-form"
                    onClick={() => {
                      if (eventData?.link) {
                        navigator.clipboard.writeText(eventData.link);
                      }
                    }}
                    title="Click to copy"
                    style={{
                      cursor: eventData?.link ? "pointer" : "default",
                      color: eventData?.link ? "#0000EE" : "#000",
                      textDecoration: eventData?.link ? "underline" : "none",
                    }}
                  >
                    {eventData?.link ?? "No Link"}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h3>Join Our Affiliate Program</h3>
                <div className="createacoount-form">
                  <Form.Group>
                    <label className="label">ADDRESS</label>
                    <Form.Control
                      type="text"
                      className="mb-0 create-input"
                      maxLength="20"
                      value={address}
                      onChange={handleInputChange(setAddress)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="createacoount-form">
                  <Form.Group>
                    <label className="label">City</label>
                    <Form.Control
                      type="text"
                      className="mb-0 create-input"
                      maxLength="20"
                      value={city}
                      onChange={handleInputChange(setCity)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="createacoount-form" >
                  <Form.Group className="web-state">
                    <label className="label">Country</label>
                    <Form.Control
                      as="select"
                      className="mb-0 create-input"
                      value={countryId}
                      onChange={(e) => setCountryId(e.target.value)}
                      id="select-set"
                    >
                      <option>Select your Country</option>
                      {countryMap?.map((res, index) => (
                        <option key={index} value={res.key}>
                          {res.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="createacoount-form">
                  <Form.Group className="web-state">
                    <label className="label">State</label>
                    <Form.Control
                      as="select"
                      className="mb-0 create-input"
                      value={stateId}
                      onChange={(e) => setStateId(e.target.value)}
                      id="select-set"
                    >
                      <option>Select your State</option>
                      {stateMap != null && stateMap?.map((res, index) => (
                        <option key={index} value={res.key}>
                          {res.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="createacoount-form" id="mob-stateview">
                  <Form.Group>
                    <label className="label">ZIP CODE</label>
                    <div id="zipcode">
                      <Form.Control
                        type="text"
                        className="mb-0 create-input"
                        value={zipcode}
                        onChange={handleZipcodeChange}
                        onKeyDown={(e) => e.key === " " && e.preventDefault()}
                        required
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="checkout-btn">
                  <button className="Create-Account" onClick={handleSubmit}>
                    submit{" "}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);
