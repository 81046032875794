import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { selectemployeeData } from "../../store/home/home.selectors";

const mapStateToProps = (state) => {
  return { employeeData: selectemployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      employeeEmail: null,
      orderId: this.props.orderId,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var employeeData = this.props.employeeData;
    if (
      this.props.location?.state?.orderId != null &&
      employeeData?.email != null
    ) {
      this.setState({
        employeeEmail: employeeData.email,
        orderId: this.props.location.state.orderId,
      });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="todays-portal-home">
        <div className="portal-confirmation">
          <div className="app-confirmation">
            <p>Enjoy the show!</p>
            <h3>We’ve sent a reciept to {this.state.employeeEmail}</h3>
            <h4>
              Order ID: <span>{this.state.orderId}</span>
            </h4>

            <p className="myaccount">
              You can retrieve your tickets by logging into your Portal “My
              Account” page.
            </p>
            {/* <div>
              <img src="" className="add-tickets1" alt="[ ticket 1 ]"></img>
            </div>
            <img src="" alt="[ ticket 2 ]" className="add-tickets2"></img> */}
            <h6>
              Portal <br></br>
              MAY 25th - 26th, 2024 <br></br>
              Bader Field, Atlantic City NJ <br></br>
            </h6>
            <Link
              target="_blank"
              to={{ pathname: "https://goo.gl/maps/85XA4WzBDSsGtEaq8" }}
            >
              <img
                src={require("../../assets/images/location-icon.png")}
                className="location-icon-confirm"
                alt="Location Pin"
              ></img>
            </Link>
            <hr className="confirm-border"></hr>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
